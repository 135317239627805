import React, { Component } from "react";
import Constant from "../common/constants";
import redirectURL from "../redirectURL";
import Select from "react-select";
import getToolConfiguration from "./commonFunctions";
import FixedVehicleCost from "./vehicleFixedCost";
import VehicleCityLevelCost from "./vehicleCityLevelVariableCost";
import VehicleLevelCost from "./vehicleLevelVariableCost";
import ZoneLevelCost from "./zonelevelcost";
import Vehiclecostcustomerspecific from "./vehiclecostcustomerspecific";
import FTLCostData from "./ftlCost";

export default class VehicleCostConfigurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationNames: [],
      location: "",
      fixedCostTab: "",
      vehicleLevelCostTab: "",
      cityWiseCostTab: "",
      zoneWiseCostTab: "",
      ftlcustomercostTab: "",
      fixedCostData: false,
      vehicleLevelCostData: false,
      cityWiseCostData: false,
      zoneWiseCostData: false,
      customercostTab: "activet",
      customercostData: true,
      ftlcustomercostData: false,
    };
  }

  async componentDidMount() {
    var curl = window.location.search;
    var ul = curl.split("?");
    // console.log("ul ",ul)
    // let url_type = window.location.href
    let url_type = ul[parseInt(ul.length) - 1];
    // console.log("url_type ", url_type)
    // let path_type = url_type.split('/')
    let path_type = url_type;
    let data_type;
    // let path = path_type[path_type.length-1]
    let path = url_type;

    if (path == "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    await this.setState({ data_type: data_type });

    let params = {
      data_type: data_type,
    };
    await getToolConfiguration(params);
    this.toolConfigurationSetup();
    await this.boundTypeLocations(params);
  }

  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));

    let locationNames = [];

    if (location_access == null) {
      locationNames.push({ label: "ALL", value: "" });
    }

    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                locationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
            });
            await this.setState({
              locationNames: locationNames,
              location: locationNames[0],
            });
          }
        }
      });
  };

  locationChanged = async (location) => {
    this.setState({
      location: location,
    });
    await getToolConfiguration({ plant_code: location.value });
    this.toolConfigurationSetup();
  };

  toolConfigurationSetup = () => {
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    var plant_wise_freight_fixed_cost = 0;
    var plant_wise_freight_vehicle_type_variable_cost = 0;
    var plant_wise_freight_city_level_vehicle_cost = 0;
    var plant_wise_freight_zone_level_cost = 0;

    if (toolConfig != undefined && toolConfig != "") {
      if (toolConfig.length > 0) {
        plant_wise_freight_fixed_cost =
          toolConfig[0].plant_wise_freight_fixed_cost;
        plant_wise_freight_vehicle_type_variable_cost =
          toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
        plant_wise_freight_city_level_vehicle_cost =
          toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
        plant_wise_freight_zone_level_cost = toolConfig[0].zone_level_cost;
      }

      if (plant_wise_freight_fixed_cost == 1) {
        this.setState({ fixedCostTab: "activet", fixedCostData: true });
      } else if (plant_wise_freight_vehicle_type_variable_cost == 1) {
        this.setState({
          vehicleLevelCostTab: "activet",
          vehicleLevelCostData: true,
        });
      } else if (plant_wise_freight_city_level_vehicle_cost == 1) {
        this.setState({ cityWiseCostTab: "activet", cityWiseCostData: true });
      } else if (plant_wise_freight_zone_level_cost == 1) {
        this.setState({ zoneWiseCostTab: "activet", zoneWiseCostData: true });
      }
    }
  };

  onClickTab = (pageType) => {
    var fixedCostTab = "activet";
    var cityWiseCostTab = "";
    var vehicleLevelCostTab = "";
    var zoneWiseCostTab = "";
    var customercostTab = "";
    var ftlcustomercostTab = "";
    var fixedCostData = true;
    var vehicleLevelCostData = false;
    var cityWiseCostData = false;
    var zoneWiseCostData = false;
    var customercostData = false;
    var ftlcustomercostData = false;
    if (pageType == "fixedCostData") {
      fixedCostTab = "activet";
      cityWiseCostTab = "";
      vehicleLevelCostTab = "";
      zoneWiseCostTab = "";
      fixedCostData = true;
      vehicleLevelCostData = false;
      cityWiseCostData = false;
      zoneWiseCostData = false;
      customercostTab = "";
      customercostData = false;
      ftlcustomercostTab = "";
      ftlcustomercostData = false;
    } else if (pageType == "vehicleLevelCostData") {
      fixedCostTab = "";
      vehicleLevelCostTab = "activet";
      cityWiseCostTab = "";
      zoneWiseCostTab = "";
      fixedCostData = false;
      vehicleLevelCostData = true;
      cityWiseCostData = false;
      zoneWiseCostData = false;
      customercostTab = "";
      customercostData = false;
      ftlcustomercostTab = "";
      ftlcustomercostData = false;
    } else if (pageType == "cityWiseCostData") {
      fixedCostTab = "";
      vehicleLevelCostTab = "";
      cityWiseCostTab = "activet";
      zoneWiseCostTab = "";
      fixedCostData = false;
      vehicleLevelCostData = false;
      cityWiseCostData = true;
      zoneWiseCostData = false;
      customercostTab = "";
      customercostData = false;
      ftlcustomercostTab = "";
      ftlcustomercostData = false;
    } else if (pageType == "zoneWiseCostData") {
      fixedCostTab = "";
      vehicleLevelCostTab = "";
      cityWiseCostTab = "";
      zoneWiseCostTab = "activet";
      fixedCostData = false;
      vehicleLevelCostData = false;
      cityWiseCostData = false;
      zoneWiseCostData = true;
      customercostTab = "";
      customercostData = false;
      ftlcustomercostTab = "";
      ftlcustomercostData = false;
    } else if (pageType == "customercostData") {
      fixedCostTab = "";
      vehicleLevelCostTab = "";
      cityWiseCostTab = "";
      zoneWiseCostTab = "";
      fixedCostData = false;
      vehicleLevelCostData = false;
      cityWiseCostData = false;
      zoneWiseCostData = true;
      customercostTab = "activet";
      customercostData = true;
      ftlcustomercostTab = "";
      ftlcustomercostData = false;
    } else if (pageType === "ftlcustomercostData") {
      fixedCostTab = "";
      vehicleLevelCostTab = "";
      cityWiseCostTab = "";
      zoneWiseCostTab = "";
      fixedCostData = false;
      vehicleLevelCostData = false;
      cityWiseCostData = false;
      zoneWiseCostData = false;
      customercostTab = "";
      customercostData = false;
      ftlcustomercostTab = "activet";
      ftlcustomercostData = true;
    }
    this.setState({
      fixedCostTab: fixedCostTab,
      vehicleLevelCostTab: vehicleLevelCostTab,
      cityWiseCostTab: cityWiseCostTab,
      fixedCostData: fixedCostData,
      vehicleLevelCostData: vehicleLevelCostData,
      cityWiseCostData: cityWiseCostData,
      zoneWiseCostData: zoneWiseCostData,
      zoneWiseCostTab: zoneWiseCostTab,
      customercostTab: customercostTab,
      customercostData: customercostData,
      ftlcustomercostTab,
      ftlcustomercostData,
    });
  };

  render() {
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    var plant_wise_freight_configuration = 0;
    var plant_wise_freight_fixed_cost = 0;
    var plant_wise_freight_vehicle_type_variable_cost = 0;
    var plant_wise_freight_city_level_vehicle_cost = 0;
    var plant_wise_freight_zone_level_cost = 0;

    if (toolConfig !== undefined && toolConfig !== "") {
      if (toolConfig.length > 0) {
        plant_wise_freight_configuration =
          toolConfig[0].plant_wise_freight_configuration;
        plant_wise_freight_fixed_cost =
          toolConfig[0].plant_wise_freight_fixed_cost;
        plant_wise_freight_vehicle_type_variable_cost =
          toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
        plant_wise_freight_city_level_vehicle_cost =
          toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
        plant_wise_freight_zone_level_cost = toolConfig[0].zone_level_cost;
      }
    }
    var {
      location,
      locationNames,
      fixedCostData,
      vehicleLevelCostData,
      cityWiseCostData,
      fixedCostTab,
      vehicleLevelCostTab,
      cityWiseCostTab,
      zoneWiseCostData,
      zoneWiseCostTab,
      customercostData,
      customercostTab,
      ftlcustomercostData,
      ftlcustomercostTab,
    } = this.state;

    var userCheck = localStorage.getItem("role") === "admin";

    var locationAcces = JSON.parse(localStorage.getItem("location_access"));
    console.log("first", locationAcces);
    var locationCheck = false;
    if (locationAcces !== null) {
      locationCheck = locationAcces.includes("LK1SLK02");
    }
    var email = localStorage.getItem("email").toLocaleLowerCase();
    var mailCheck = true;
    if (email.includes("nestle.com")) {
      mailCheck = false;
    }

    return (
      <div className="">
        <div className="row mb-5p pt-5p">
          <div className="col-sm-12">
            <h5 className="fbold  d-flex justify-content-between">
              <span>Vehicle Cost Configurations</span>
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-sm-12">
            <div className="form-group ">
              <label className="col-form-label f12">Pickup Location</label>
              <Select
                placeholder={"Select"}
                onChange={this.locationChanged}
                name="location"
                value={location}
                options={locationNames}
              />
            </div>
          </div>

          <div className="col-md-8 col-sm-12 mt-35p">
            <ul className="d-tabs">
              {/* start  */}
              {/* {plant_wise_freight_fixed_cost == 1 ? (
                <li
                  onClick={this.onClickTab.bind(this, "fixedCostData")}
                  className={"lttabs " + fixedCostTab}
                >
                  <button type="button" className="btn">
                    Fixed Vehicle Cost
                  </button>
                </li>
              ) : (
                ""
              )}

              {plant_wise_freight_vehicle_type_variable_cost == 1 ? (
                <li
                  onClick={this.onClickTab.bind(this, "vehicleLevelCostData")}
                  className={"lttabs " + vehicleLevelCostTab}
                >
                  <button type="button" className="btn">
                    Variable Vehicle Cost
                  </button>
                </li>
              ) : (
                ""
              )}

              {plant_wise_freight_city_level_vehicle_cost == 1 ? (
                <li
                  onClick={this.onClickTab.bind(this, "cityWiseCostData")}
                  className={"lttabs " + cityWiseCostTab}
                >
                  <button type="button" className="btn">
                    City Wise Vehicle Cost
                  </button>
                </li>
              ) : (
                ""
              )}

              {plant_wise_freight_zone_level_cost == 1 ? (
                <li
                  onClick={this.onClickTab.bind(this, "zoneWiseCostData")}
                  className={"lttabs " + zoneWiseCostTab}
                >
                  <button type="button" className="btn">
                    Zone Wise Cost
                  </button>
                </li>
              ) : (
                ""
              )} */}
              {/* start  */}
              <li
                onClick={this.onClickTab.bind(this, "customercostData")}
                className={"lttabs " + customercostTab}
              >
                <button type="button" className="btn">
                  Cost Details
                </button>
              </li>
              {mailCheck ? (
                <li
                  onClick={this.onClickTab.bind(this, "ftlcustomercostData")}
                  className={"lttabs " + ftlcustomercostTab}
                >
                  <button type="button" className="btn">
                    FTL Cost Details
                  </button>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
        {customercostData ? (
          location != "" ? (
            <Vehiclecostcustomerspecific plantLocation={location} />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {ftlcustomercostData ? (
          location !== "" ? (
            <FTLCostData plantLocation={location} />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {/* start  */}
        {/* {fixedCostData ? (
          location != "" ? (
            <FixedVehicleCost plantLocation={location} />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {vehicleLevelCostData ? (
          location != "" ? (
            <VehicleLevelCost plantLocation={location} />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {cityWiseCostData ? (
          location != "" ? (
            <VehicleCityLevelCost plantLocation={location} />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {zoneWiseCostData ? (
          location != "" ? (
            <ZoneLevelCost plantLocation={location} />
          ) : (
            ""
          )
        ) : (
          ""
        )} */}
        {/* start  */}
      </div>
    );
  }
}
