import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Constant from "../common/constants";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import GridButton from "./gridButton";
import NAButton from "./naButton";
import DeletetxtButton from "./deletetxtbtn";
import getToolConfiguration from "./commonFunctions";
import { logFormChanges } from "./auditLogfunction";
import moment from "moment";
// var moment = require("moment");

export default class FTLCostData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
        NAButton: NAButton,
        DeletetxtButton,
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      openMaterialConfigurationModel: false,
      show: false,
      basicTitle: "",
      basicType: "default",
      data_type: "",
      location: this.props.plantLocation,
      vehicle_type: "",
      vehicleTypeList: [],
      fixed_vehicle_cost: "",
      rowId: "",
      previousData: "",
      transporterList: [],
      transporter_name: "",
      validity_to: "",
      validity_from: "",
      cost_per_km_after_SOB: "",
      cost_per_km_below_SOB: "",
      start_date: moment
        .parseZone()
        .subtract(1, "months")
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      end_date: moment
        .parseZone()
        .add(1, "months")
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      vehicle_product_type: "",
      editLocation: "",
      vehicleSpecificCost_id: "",
      location_cost: "",
      location_code: "",
      location_zone: "",
    };
  }

  async componentDidMount() {
    var curl = window.location.search;
    var ul = curl.split("?");
    // console.log("ul ",ul)
    // let url_type = window.location.href
    let url_type = ul[parseInt(ul.length) - 1];
    // console.log("url_type ", url_type)
    // let path_type = url_type.split('/')
    let path_type = url_type;
    let data_type;
    // let path = path_type[path_type.length-1]
    let path = url_type;
    if (path == "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    // const {plantLocation} = this.props
    await this.setState({
      data_type: data_type,
      // location:plantLocation
    });
    let params = {
      data_type: data_type,
      location_code: this.props.plantLocation.value,
    };
    let toolparameters = {
      data_type: data_type,
      plant_code: this.props.plantLocation.value,
    };
    // var k={username:'Ram Krishna',email:'ram.krishan3@in.nestle.com',password:'Nestle@123'}
    // redirectURL.post('/users/saveUser',k)
    await getToolConfiguration(toolparameters);
    await this.getVehiclesData(params);
    await this.getCustomerSpecificVehicleCostData(params);
    await this.getTransporterDetails(params);
  }
  componentWillReceiveProps(newprops) {
    if (newprops.plantLocation !== this.state.location) {
      var curl = window.location.search;
      var ul = curl.split("?");
      // console.log("ul ",ul)
      // let url_type = window.location.href
      let url_type = ul[parseInt(ul.length) - 1];
      // console.log("url_type ", url_type)
      // let path_type = url_type.split('/')
      let path_type = url_type;
      let data_type;
      // let path = path_type[path_type.length-1]
      let path = url_type;

      if (path == "inbound") {
        data_type = 1;
      } else {
        data_type = 2;
      }
      // console.log("location ", newprops.plantLocation)
      this.setState({
        location: newprops.plantLocation,
      });
      let params = {
        data_type: data_type,
        location_code: newprops.plantLocation.value,
      };
      this.getVehiclesData(params);
      this.getTransporterDetails(params);
    }
  }

  getVehiclesData = (params) => {
    let vehicleTypeList = [];
    redirectURL
      .post("master/getVehiclesData", params)
      .then(async (response) => {
        if (response.data.status == "success") {
          let vehiclesData = response.data.vehiclesData;
          if (vehiclesData.length > 0) {
            vehiclesData.map((item) => {
              vehicleTypeList.push({
                value: item.vehicle_type,
                label: item.vehicle_type,
              });
            });
            await this.setState({
              vehicleTypeList: vehicleTypeList,
              vehicle_type: vehicleTypeList[0],
            });
          }
        }
      });
  };

  getCustomerSpecificVehicleCostData = async (params) => {
    params.validity_from = this.state.start_date;
    params.validity_to = this.state.end_date;
    await redirectURL
      .post("master/getCustomerFTLCostData", params)
      .then(async (response) => {
        // console.log("first res", response.data);
        if (response.status === 200) {
          await this.setState({
            rowData: response.data,
          });
        }
      });
  };

  //function names need to be updated
  openModelForMaterialConfiguration = () => {
    this.setState((prevState) => ({
      openMaterialConfigurationModel: !prevState.openMaterialConfigurationModel,
    }));
  };

  handelMaterialConfigurationModelCancel = () => {
    this.setState((prevState) => ({
      openMaterialConfigurationModel: !prevState.openMaterialConfigurationModel,
      vehicle_type: "",
      rowId: "",
      transporter_name: "",
      validity_to: "",
      validity_from: "",
      cost_per_km_after_SOB: "",
      cost_per_km_below_SOB: "",
      vehicle_product_type: "",
      location_code: "",
      location_zone: "",
      location_cost: "",
    }));
  };

  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };

  materialDetailsInputFields = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  saveFTLSpecificVehicleCostDetails = (params) => {
    var data_type = this.state.data_type;
    console.log("first params", params);
    redirectURL
      .post("master/saveFTLSpecificVehicleCostDetails", params)
      .then((response) => {
        console.log("first res", response.data);
        if (response.data.status == "success") {
          let param = {
            data_type: data_type,
          };
          this.getCustomerSpecificVehicleCostData(param);
          this.setState({
            openMaterialConfigurationModel: false,
            transporter_name: "",
            validity_to: "",
            validity_from: "",
            cost_per_km_after_SOB: "",
            cost_per_km_below_SOB: "",
            vehicle_type: "",
            vehicle_product_type: "",
            show: true,
            basicTitle: "Data Saved Successfully",
            basicType: "success",
            location_code: "",
            location_zone: "",
            location_cost: "",
          });
        } else {
          this.setState({
            show: true,
            basicTitle: response.data.message,
            basicType: "danger",
          });
        }
      });
  };

  updateFTLSpecificVehicleCostDetails = (params) => {
    let data_type = this.state.data_type;
    redirectURL
      .post("master/updateFTLSpecificVehicleCostDetails", params)
      .then((response) => {
        if (response.data.status === "success") {
          let param = {
            data_type: data_type,
          };
          let logParams = {
            user_name: localStorage.getItem("username"),
            useremail: localStorage.getItem("email"),
            client: localStorage.getItem("client"),
            screen: "Freight configurations - Vehicle Cost",
            location_code: this.state.location.value,
            activity: {},
          };
          let previousData = this.state.previousData;
          logFormChanges(logParams, params, previousData);

          this.getCustomerSpecificVehicleCostData(param);
          this.setState({
            openMaterialConfigurationModel: false,
            transporter_name: "",
            validity_to: "",
            validity_from: "",
            cost_per_km_after_SOB: "",
            cost_per_km_below_SOB: "",
            vehicle_type: "",
            rowId: "",
            previousData: "",
            vehicle_product_type: "",
            show: true,
            basicTitle: "Data Updated Successfully",
            basicType: "success",
            location_code: "",
            location_zone: "",
            location_cost: "",
          });
        } else {
          this.setState({
            show: true,
            basicTitle: "Failed to save the data",
            basicType: "danger",
          });
        }
      });
  };

  formMaterialDetails = (e) => {
    e.preventDefault();
    var transporter_name = this.state.transporter_name;
    // var validity_to = this.state.validity_to;
    // var validity_from = this.state.validity_from;
    // var cost_per_km_after_SOB = this.state.cost_per_km_after_SOB;
    var location_code = this.state.location_code;
    var { location_cost, location_zone } = this.state;
    var vehicle_type = this.state.vehicle_type;
    var data_type = this.state.data_type;
    var location = !this.state.location.value
      ? this.state.editLocation
      : this.state.location;
    // var toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    var rowId = this.state.rowId;
    // var vehicleSpecificCost_id = this.state.vehicleSpecificCost_id;
    var vehicle_product_type = this.state.vehicle_product_type;
    // console.log("first", location);
    var params = {
      plant_location_code: location.value,
      vehicle_type: vehicle_type.value,
      transporter_name: transporter_name.label,
      transporter_code: transporter_name.value,
      // cost_per_km_below_SOB: parseFloat(cost_per_km_below_SOB),
      // cost_per_km_after_SOB: parseFloat(cost_per_km_after_SOB),
      // validity_from: validity_from,
      // validity_to: validity_to,
      data_type: data_type,
      location_name: location.label,
      vehicle_product_type,
      location_cost,
      location_zone,
      location_code,
    };

    if (rowId !== "") {
      // params.vehicleSpecificCost_id = vehicleSpecificCost_id;
      params.rowId = rowId;
      this.updateFTLSpecificVehicleCostDetails(params);
    } else {
      if (location.value != null && vehicle_type.value != null) {
        if (this.state.validity_from > this.state.validity_to) {
          this.setState({
            show: true,
            basicTitle: "From Date should be less than To Date",
            basicType: "danger",
          });
          // return;
        } else {
          this.saveFTLSpecificVehicleCostDetails(params);
        }
      } else {
        if (vehicle_type.value == null) {
          this.setState({
            show: true,
            basicTitle: "Please Select Vehicle",
            basicType: "danger",
          });
        } else {
          this.setState({
            show: true,
            basicTitle:
              "Please add " +
              Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION +
              " First",
            basicType: "danger",
          });
        }
      }
    }
  };

  editMaterialConfiguration = async (propdata) => {
    console.log("first", propdata.data.location_zone);
    let data = propdata.data;
    var previousData = {
      vehicle_type: data.vehicle_type,
      transporter_name: data.transporter_name,
      validity_to: data.validity_to,
      validity_from: data.validity_from,
      cost_per_km_after_SOB: data.cost_per_km_after_SOB,
      cost_per_km_below_SOB: data.cost_per_km_below_SOB,
      vehicle_product_type: data.vehicle_product_type,
      editLocation: {
        value: data.plant_location_code,
        label: data.plant_location_code,
      },
    };
    await this.setState({
      vehicle_type: { label: data.vehicle_type, value: data.vehicle_type },
      transporter_name: {
        label: data.transporter_code,
        value: data.transporter_code,
      },
      validity_to: moment
        .parseZone(data.validity_to)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      validity_from: moment
        .parseZone(data.validity_from)
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      location_cost: data.location_cost,
      location_code: data.location_code,
      location_zone: data.location_zone,
      rowId: data._id,
      vehicleSpecificCost_id: data.vehicleSpecificCost_id,
      previousData: previousData,
      vehicle_product_type: data.vehicle_product_type,
      editLocation: {
        value: data.plant_location_code,
        label: data.plant_location_code,
      },
    });
    this.openModelForMaterialConfiguration();
  };

  vehicleTypeChanged = (vehicle_type) => {
    this.setState({
      vehicle_type: vehicle_type,
    });
  };

  deleteFTLSpecificVehicleCostDetails = (propData) => {
    let data = propData.data;
    let data_type = this.state.data_type;

    var cnf = window.confirm("Do you want to proceed?");
    if (cnf) {
      let params = {
        delete_id: data._id,
      };
      redirectURL
        .post("master/deleteFTLSpecificVehicleCostDetails", params)
        .then((response) => {
          if (response.data.status == "success") {
            let param = {
              data_type: data_type,
            };
            this.getCustomerSpecificVehicleCostData(param);
          } else {
            this.setState({
              show: true,
              basicTitle: "Failed to delete the data",
              basicType: "danger",
            });
          }
        });
    }
  };

  transporterChanged = (transporter_name) => {
    this.setState({
      transporter_name: transporter_name,
    });
  };

  getTransporterDetails = async (param) => {
    let transporterList = [];
    let duplicateTransporterList = [];
    await redirectURL
      .post("master/getTransporterDetails", param)
      .then(async (response) => {
        // console.log("response.data ", response.data)
        if (response.data.status == "success") {
          let transporters = response.data.transporterDetailsData;

          if (transporters.length > 0) {
            transporters.map((item) => {
              transporterList.push({
                value: item.transporter_code,
                label: item.transporter_name,
              });
            });
            // console.log(transporterList,"transporterList")
            let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
            let transporter_name = "";
            // if(toolConfig[0].plant_wise_transporter_configuration == 0){
            //   transporter_name = {label : 'LSP', value : 'LSP'};
            // }
            await this.setState({
              transporterList: transporterList,
              transporter_name: transporter_name,
            });
          } else {
            let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
            if (toolConfig[0].plant_wise_transporter_configuration == 0) {
              this.setState({
                transporter_name: { label: "LSP", value: "LSP" },
              });
            } else {
              this.setState({
                transporterList: [],
                transporter_name: null,
              });
            }
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getTransporterDetails",
          screen: "Freight Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  onClickGetData = async () => {
    if (this.state.start_date > this.state.end_date) {
      this.setState({
        show: true,
        basicTitle: "Start Date should be less than End Date",
        basicType: "danger",
      });
      return;
    }
    let param = {
      data_type: this.state.data_type,
    };
    await this.getCustomerSpecificVehicleCostData(param);
  };

  render() {
    var colsDefs = [
      {
        headerName: "Plant Name",
        field: "plant_location_code",
        width: "130",
      },
      {
        headerName: "Location Code",
        field: "location_code",
        width: "150",
      },
      {
        headerName: "Location Zone",
        field: "location_zone",
        width: "100",
      },
      {
        headerName: "Transport Code",
        field: "transporter_code",
        width: "180",
      },
      {
        headerName: "Vehicle Product Type",
        field: "vehicle_product_type",
        width: "180",
      },
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: "180",
      },
      {
        headerName: "Cost (Rs)",
        field: "location_cost",
        width: "100",
      },
      // {
      //   headerName: "Plant Name",
      //   field: "location_name",
      //   width: "180",
      // },
      // {
      //   headerName: "Plant Name",
      //   field: "location_name",
      //   width: "180",
      // },
      // {
      //   headerName: Constant.VEHICLE_COST_CONFIGURATION_VEHICLE_TYPE,
      //   field: "vehicle_type",
      //   width: "120",
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.status != undefined) {
      //         if (params.data.status == 0) {
      //           return "redbg";
      //         }
      //       }
      //     } catch (error) {}
      //   },
      // },
      // {
      //   headerName: "Transporter",
      //   field: "transporter_name",
      //   width: "130",
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.status != undefined) {
      //         if (params.data.status == 0) {
      //           return "redbg";
      //         }
      //       }
      //     } catch (error) {}
      //   },
      // },
      // {
      //   headerName: "Cost Per KM (Below SOB)",
      //   field: "cost_per_km_below_SOB",
      //   width: "130",
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.status != undefined) {
      //         if (params.data.status == 0) {
      //           return "redbg";
      //         }
      //       }
      //     } catch (error) {}
      //   },
      // },
      // {
      //   headerName: "Cost Per KM (After SOB)",
      //   field: "cost_per_km_after_SOB",
      //   width: "130",
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.status != undefined) {
      //         if (params.data.status == 0) {
      //           return "redbg";
      //         }
      //       }
      //     } catch (error) {}
      //   },
      // },
      // {
      //   headerName: "Validity From",
      //   field: "validity_from",
      //   width: "130",
      //   valueGetter: function (params) {
      //     if (params.data.validity_from) {
      //       return moment
      //         .parseZone(params.data.validity_from)
      //         .format("YYYY-MM-DD");
      //     }
      //   },
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.status != undefined) {
      //         if (params.data.status == 0) {
      //           return "redbg";
      //         }
      //       }
      //     } catch (error) {}
      //   },
      // },
      // {
      //   headerName: "Validity To",
      //   field: "validity_to",
      //   width: "130",
      //   valueGetter: function (params) {
      //     if (params.data.validity_to) {
      //       return moment
      //         .parseZone(params.data.validity_to)
      //         .format("YYYY-MM-DD");
      //     }
      //   },
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.status != undefined) {
      //         if (params.data.status == 0) {
      //           return "redbg";
      //         }
      //       }
      //     } catch (error) {}
      //   },
      // },
      // {
      //   headerName: "Vehicle Product Type",
      //   field: "vehicle_product_type",
      //   width: "130",
      //   valueGetter: function (params) {
      //     if (params.data.vehicle_product_type) {
      //       return params.data.vehicle_product_type;
      //     }
      //   },
      //   cellClass: function (params) {
      //     try {
      //       if (params.data.status !== undefined) {
      //         if (params.data.status === 0) {
      //           return "redbg";
      //         }
      //       }
      //     } catch (error) {}
      //   },
      // },
      {
        headerName: "Actions",
        field: "cluster",
        width: "80",
        pinned: "left",
        params: {
          buttonName: "Edit",
          onClickFunction: this.editMaterialConfiguration,
        },
        cellRendererSelector: function (params) {
          if (params.data.status != undefined) {
            if (params.data.status == 0) {
              var rendComponent = {
                component: "NAButton",
              };
            } else {
              var rendComponent = {
                component: "GridButton",
              };
            }
          }

          return rendComponent;
        },
        cellClass: function (params) {
          try {
            if (params.data.status != undefined) {
              if (params.data.status == 0) {
                return "redbg";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "",
        field: "cluster",
        width: "80",
        pinned: "left",
        params: {
          buttonName: "Delete",
          iconName: "fa fa-trash",
          onClickFunction: this.deleteFTLSpecificVehicleCostDetails,
        },
        cellRendererSelector: function (params) {
          if (params.data.status != undefined) {
            if (params.data.status == 0) {
              var rendComponent = {
                component: "DeletetxtButton",
              };
            } else {
              var rendComponent = {
                component: "GridButton",
              };
            }
          }
          return rendComponent;
        },
        cellClass: function (params) {
          try {
            if (params.data.status != undefined) {
              if (params.data.status == 0) {
                return "redbg";
              }
            }
          } catch (error) {}
        },
      },
    ];

    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    let data = this.state.rowData;
    let locValue = this.state.location.value;

    var filteredData = [];
    if (!locValue) {
      filteredData = this.state.rowData;
    } else {
      filteredData = data.filter(
        (item) => item.plant_location_code === locValue
      );
    }
    // console.log("first data", this.state.rowData, filteredData);
    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div class="row mb-20p pt-0p">
          <div className="col-sm-12">
            <h5 className="fbold  d-flex">
              {/* start */}
              {/* <span>Vehicle Cost</span>
                  <label className="col-form-label f12">Validity From </label>
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group ">
                      <input
                        type="date"
                        className="forminp form-control"
                        required
                        name="start_date"
                        value={this.state.start_date}
                        onChange={this.materialDetailsInputFields}
                        placeholder=""
                        autoComplete="off"
                        readonly
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <label className="col-form-label f12">Validity To </label>
                  <div className="col-md-2 col-sm-12">
                    <div className="form-group ">
                      <input
                        type="date"
                        className="forminp form-control"
                        required
                        name="end_date"
                        value={this.state.end_date}
                        onChange={this.materialDetailsInputFields}
                        placeholder=""
                        autoComplete="off"
                        readonly
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button
                      type="button"
                      onClick={this.onClickGetData}
                      className="btn btn-warning"
                    >
                      Get Data
                    </button>
                  </div> */}
              {/* start */}
              <div className="col d-flex justify-content-end">
                <a
                  href="javascript:;"
                  onClick={this.openModelForMaterialConfiguration}
                  style={{
                    pointerEvents: !this.state.location.value ? "none" : "auto",
                    backgroundColor: !this.state.location.value
                      ? "#ddd"
                      : "#fff",
                  }}
                  className="f18 btn btn-default float-right themefont fbold mr-10p mb-10p"
                >
                  + Add Vehicle Cost
                </a>
              </div>
            </h5>

            <div
              id="myGrid"
              style={{ width: "100%", height: "70vh" }}
              className="col-sm-12 ag-theme-balham dropdown"
            >
              <AgGridReact
                modules={this.state.modules}
                rowData={filteredData}
                columnDefs={colsDefs}
                gridOptions={{
                  context: { componentParent: this },
                  getContextMenuItems: (params) => {
                    return [
                      "copy", // Default AG Grid options like copy
                      "copyWithHeaders",
                      "paste",
                      {
                        name: "Export to CSV", // Custom option for exporting to CSV
                        action: () => {
                          params.api.exportDataAsCsv();
                        },
                      },
                      {
                        name: "Export to Excel", // Custom option for exporting to Excel
                        action: () => {
                          params.api.exportDataAsExcel();
                        },
                      },
                    ];
                  },
                }}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
                enableRangeSelection={true}
              />
            </div>
          </div>
        </div>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.openMaterialConfigurationModel}
          onClose={this.handelMaterialConfigurationModelCancel}
          classNames={{ modal: "pickupdrop-modelClass" }}
        >
          <div className="col-sm-12">
            <div>
              <h4>Cost Details</h4>
            </div>
            <form className="theme-form" onSubmit={this.formMaterialDetails}>
              <div className="row number">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Pickup Location
                    </label>
                    {/* {console.log(
                      "first loca",
                      !this.state.location.value,
                      this.state.location.value === ""
                    )} */}
                    <Select
                      isDisabled
                      name="location"
                      // value={this.state.location}
                      value={
                        !this.state.location.value
                          ? this.state.editLocation
                          : this.state.location
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Vehicle Type</label>
                    <Select
                      placeholder={"Select"}
                      onChange={this.vehicleTypeChanged}
                      name="vehicle_type"
                      value={this.state.vehicle_type}
                      options={this.state.vehicleTypeList}
                      maxMenuHeight={"170px"}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Transporter</label>
                    {/* start */}
                    {/* <input
                      type="text"
                      className="forminp form-control"
                      name="transporter_name"
                      value={this.state.transporter_name}
                      onChange={this.vehicleConfigurationInputFields}
                      autoComplete="off"
                    /> */}
                    {/* start */}
                    <Select
                      required
                      placeholder={"Select"}
                      onChange={this.transporterChanged}
                      name="transporter_name"
                      value={this.state.transporter_name}
                      options={this.state.transporterList}
                    />
                  </div>
                </div>

                {/* <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Cost Per KM (Below SOB){" "}
                    </label>
                    <input
                      required
                      type="number"
                      min="0"
                      step={"0.01"}
                      className="forminp form-control"
                      id="cost_per_km_below_SOB"
                      name="cost_per_km_below_SOB"
                      value={this.state.cost_per_km_below_SOB}
                      onChange={this.materialDetailsInputFields}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div> */}

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Cost</label>
                    <input
                      required
                      type="number"
                      min="0"
                      step={"0.01"}
                      className="forminp form-control"
                      id="location_cost"
                      name="location_cost"
                      value={this.state.location_cost}
                      onChange={this.materialDetailsInputFields}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Location Code</label>
                    <input
                      required
                      type="number"
                      min="0"
                      step={"0.01"}
                      className="forminp form-control"
                      id="location_code"
                      name="location_code"
                      value={this.state.location_code}
                      onChange={this.materialDetailsInputFields}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Location Zone</label>
                    <input
                      required
                      type="text"
                      min="0"
                      step={"0.01"}
                      className="forminp form-control"
                      id="location_zone"
                      name="location_zone"
                      value={this.state.location_zone}
                      onChange={this.materialDetailsInputFields}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Validity From </label>
                    <input
                      type="date"
                      className="forminp form-control"
                      required
                      name="validity_from"
                      value={this.state.validity_from}
                      onChange={this.materialDetailsInputFields}
                      placeholder=""
                      autoComplete="off"
                      readonly
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Validity To </label>
                    <input
                      type="date"
                      className="forminp form-control"
                      required
                      name="validity_to"
                      value={this.state.validity_to}
                      onChange={this.materialDetailsInputFields}
                      placeholder=""
                      autoComplete="off"
                      readonly
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div> */}

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label
                      htmlFor="vehicle_product_type"
                      className="col-form-label f12"
                    >
                      Vehicle Product Type{" "}
                      <span className="redColor fbold">*</span>
                    </label>

                    <select
                      name="vehicle_product_type"
                      id="vehicle_product_type"
                      value={this.state.vehicle_product_type}
                      required
                      className="forminp form-control"
                      onChange={this.materialDetailsInputFields}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="AMBIENT">AMBIENT</option>
                      <option value="REEFER">REEFER</option>
                      <option value="CHILLED">CHILLED</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn tbtn float-right save-btn"
                  id="saveGeo"
                >
                  SAVE
                </button>
                <button
                  type="button"
                  className="btn tbtn float-right cancel-btn"
                  onClick={this.handelMaterialConfigurationModelCancel}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </>
    );
  }
}
