import React, { Component } from "react";
import ReactDOM from "react-dom";
var redirectURL = require("../redirectURL");

const GpsAction = (props) => {
  const handleEventClick = (e) => {
    e.stopPropagation();
    //console.log("Overspeed ",props);
    //props.context.componentParent.onShowTransitKpi(props);
  };

//   var colr = "greenfont";
  var colr = "txt-secondary";

  //}
  return (
    <div>
      <button
        onClick={handleEventClick}
        className="custom-btn f22 label label-success"
        title="GPS Available"
      >
        <i className={"fa fa-map-marker " + colr}></i> &nbsp;
      </button>
    </div>
  );
};

export default GpsAction;
