import React, { Component } from "react";
import Constant from "../../common/constants";
import Select from "react-select";
import redirectURL from "../../redirectURL";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import SweetAlert from "react-bootstrap-sweetalert";
import BarChart from "./BarChart";
import CountUp from "react-countup";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { getHyphenYYYYMMDDHHMMSS } from "../../common/utils";

var moment = require("moment");

const data = [{ tot: 1, spot: 2, reg: 3 }];

export default class DailySummaryCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowData: [],
      latestData: [],
      selectedFile: null,
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 1000,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      countries: [],
      locationZones: [],
      regions: [],
      locationNames: [],
      boundLocations: [],
      location: { value: "All", label: "All" },
      country: { value: "All", label: "All" },
      zone: { value: "All", label: "All" },
      region: { value: "All", label: "All" },
      invoicedate: moment()
        .subtract(1, "day") // Subtract one day
        .utcOffset("+05:30") // Set the desired timezone offset
        .format("YYYY-MM-DD"),
      dailychartsTab: "activet",
      dailydataTab: "",
      dailychartsData: true,
      dailyData: false,
      rowdata: [],
      chartData: [],
      show: false,
      basicTitle: "",
      basicType: "default",
      loadshow: "show-n",
      overly: "show-n",
      overlyRing: "show-n",
      TplannedPercentage: 0,
      Ttripcost: 0,
      TassignedTripCount: 0,
      TassignedVFR: 0,
      TplanTripCount: 0,
      TplannedVFR: 0,
      TassignedTripDistance: 0,
      TplannedTripDistance: 0,
      dispatchType: { value: "", label: "All" },
    };
  }

  async componentDidMount() {
    let url_type = window.location.href;
    // console.log(url_type,"url_type")
    let path_type = url_type.split("?");
    // console.log(path_type,"path_type")
    let data_type;
    let path = path_type[path_type.length - 1];
    if (path === "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    let params = {
      data_type: data_type,
    };
    await this.boundTypeLocations(params);
    await this.getDataForNewDashboard();
  }
  getDataForNewDashboard() {
    this.setState({ overly: "show-m", loadshow: "show-m" });
    var { country, zone, location, invoicedate, dispatchType } = this.state;
    // console.log("first cout3", country, zone);
    if (dispatchType.value) {
      dispatchType = dispatchType.value;
    } else {
      dispatchType = "";
    }
    // console.log(
    //   "first invoicedate",
    //   invoicedate == "",
    //   invoicedate === "",
    //   !invoicedate
    // );
    if (!invoicedate) {
      this.setState({
        show: true,
        basicTitle: "Please Select Invoice Date...!",
        basicType: "danger",
      });
      return;
    }
    var params = {
      country: country.value,
      zone: zone.value,
      location: location.value,
      invoice_date: invoicedate,
      dispatchType,
    };
    redirectURL
      .post("dispatch/getDataForNewDashboard", params)
      .then((response) => {
        // console.log("first response", response.data);
        if (Array.isArray(response.data.summary_data)) {
          this.setState({
            chartData: response.data.summary_data,
            // rowdata: response.data.summary_data.raw_data,
          });
          if (response.data.raw_data) {
            var rawData = JSON.parse(response.data.raw_data);
            this.setState({
              // chartData: response.data.summary_data,
              // rowdata: response.data.summary_data.raw_data,
              rowdata: rawData,
              overly: "show-n",
              loadshow: "show-n",
            });
            // console.log("first rawData", rawData);
          }
        } else {
          this.setState({
            rowdata: [],
            show: true,
            basicTitle: "No data found for the specified invoice date",
            basicType: "danger",
            overly: "show-n",
            loadshow: "show-n",
            chartData: [],
          });
        }
      });
  }
  getoutputDetailsForGrid() {
    this.setState({
      overlyRing: "show-m",
      overly: "show-m",
      overlayBlock: "show-m",
    });
    var { country, zone, location, invoicedate, dispatchType } = this.state;
    if (dispatchType.value) {
      dispatchType = dispatchType.value;
    } else {
      dispatchType = "";
    }
    var gridParams = {
      country: country.value,
      zone: zone.value,
      location: location.value,
      invoice_date: invoicedate,
      dispatchType,
    };
    // console.log("gridParams first", gridParams);
    redirectURL
      .post("dispatch/getoutputDetailsForGrid", gridParams)
      .then((response) => {
        // console.log("grid data first", response.data);
        if (response.data.length === 0) {
          this.setState({
            basicTitle: "No data found for the specified invoice date",
            basicType: "danger",
            show: true,
            rowdata: response.data,
            overly: "show-n",
          });
        } else {
          this.setState({
            rowdata: response.data,
            overlyRing: "show-n",
            overly: "show-n",
            overlayBlock: "show-n",
          });
        }
      });
  }
  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    let locationNames = [{ label: "All", value: "All" }];
    let countries = [{ label: "All", value: "All" }];
    let locationZones = [{ label: "All", value: "All" }];
    let regions = [{ label: "All", value: "All" }];
    let countriesSet = new Set();
    let locationZoneSet = new Set();
    let regionSet = new Set();
    let locationNameSet = new Set();
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status === "success") {
          let boundLocations = response.data.boundLocations;
          this.setState({
            boundLocations: response.data.boundLocations,
          });
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });

                  if (
                    item.country &&
                    item.country !== null &&
                    !countriesSet.has(item.country)
                  ) {
                    console.log("first loop", item.country);
                    countries.push({
                      value: item.country.toUpperCase(),
                      label: item.country.toUpperCase(),
                    });
                    countriesSet.add(item.country.toUpperCase());
                  }
                  if (
                    item.region &&
                    item.region !== null &&
                    !regionSet.has(item.region)
                  ) {
                    regions.push({
                      value: item.region.toUpperCase(),
                      label: item.region.toUpperCase(),
                    });
                    regionSet.add(item.region.toUpperCase());
                  }
                  if (
                    item.location_zone &&
                    item.location_zone !== null &&
                    !locationZoneSet.has(item.location_zone)
                  ) {
                    locationZones.push({
                      value: item.location_zone.toUpperCase(),
                      label: item.location_zone.toUpperCase(),
                    });
                    locationZoneSet.add(item.location_zone.toUpperCase());
                  }
                }
              } else {
                if (
                  item.country &&
                  item.country !== null &&
                  !countriesSet.has(item.country)
                ) {
                  countries.push({
                    value: item.country.toUpperCase(),
                    label: item.country.toUpperCase(),
                  });
                  countriesSet.add(item.country.toUpperCase());
                }
                if (
                  item.region &&
                  item.region !== null &&
                  !regionSet.has(item.region)
                ) {
                  regions.push({
                    value: item.region.toUpperCase(),
                    label: item.region.toUpperCase(),
                  });
                  regionSet.add(item.region.toUpperCase());
                }
                if (
                  item.location_zone &&
                  item.location_zone !== null &&
                  !locationZoneSet.has(item.location_zone)
                ) {
                  locationZones.push({
                    value: item.location_zone.toUpperCase(),
                    label: item.location_zone.toUpperCase(),
                  });
                  locationZoneSet.add(item.location_zone.toUpperCase());
                }
                if (
                  item.location_code &&
                  item.location_code !== null &&
                  item.location_name !== null &&
                  !locationNameSet.has(item.location_code)
                ) {
                  locationNames.push({
                    value: item.location_code.toUpperCase(),
                    label: item.location_name.toUpperCase(),
                  });
                  locationNameSet.add(item.location_code.toUpperCase());
                }
              }
              return true;
            });

            if (regions.length > 0) {
              await this.setState({
                regions: regions,
                region: regions[0],
              });
            } else {
              await this.setState({
                regions: [{ label: "Please Add Data", value: "no_data" }],
                region: [{ label: "Please Add Data", value: "no_data" }],
              });
            }

            if (countries.length > 0) {
              await this.setState({
                countries: countries,
                country: countries[0],
              });
            } else {
              await this.setState({
                countries: [{ label: "Please Add Data", value: "no_data" }],
                country: [{ label: "Please Add Data", value: "no_data" }],
              });
            }

            if (locationZones.length > 0) {
              await this.setState({
                locationZones: locationZones,
                zone: locationZones[0],
              });
            } else {
              await this.setState({
                locationZones: [{ label: "Please Add Data", value: "no_data" }],
                zone: [{ label: "Please Add Data", value: "no_data" }],
              });
            }
            // console.log("first locationNames", locationNames);
            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                location: locationNames[0],
              });
            } else {
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                location: [{ label: "Please Add Data", value: "no_data" }],
              });
            }
          }
        }
      });
  };
  onChangeRegion = (region) => {
    this.setState(
      {
        region: region,
        country: { value: "All", label: "All" },
        zone: { value: "All", label: "All" },
        state: { value: "All", label: "All" },
        location: { value: "All", label: "All" },
      },
      () => {
        this.fetchOptionsForRegions(region.value);
      }
    );
  };

  locationCountryChange = (country) => {
    this.setState(
      {
        country: country,
        // zone: { value: "All", label: "All" },
        // state: { value: "All", label: "All" },
        // location: { value: "All", label: "All" },
      }
      // () => {
      //   this.fetchOptionsForCountry(country.value);
      // }
    );
  };

  locationZoneChange = (zone) => {
    this.setState(
      {
        zone: zone,
        // state: { value: "All", label: "All" },
        // location: { value: "All", label: "All" },
      }
      // () => {
      //   this.fetchOptionsForBranch(zone.value);
      // }
    );
  };

  locationChanged = (location) => {
    this.setState(
      {
        location: location,
      }
      // () => {
      //   this.fetchOptionsForLocation(location.value);
      // }
    );
  };

  fetchOptionsForRegions = (value) => {
    // console.log(value, "regions first");

    let regionOptions = [{ value: "All", label: "All" }];
    let countryOptions = [{ value: "All", label: "All" }];
    let branchOptions = [{ value: "All", label: "All" }];
    let dcOptions = [{ value: "All", label: "All" }];
    let region = { value: "All", label: "All" };

    let countrySet = new Set();
    let branchSet = new Set();
    let dcSet = new Set();
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        location_access.includes(item.location_code)
      );
    }
    for (let i of boundLocations) {
      if (value !== "All") {
        if (i.region && i.region.toUpperCase() === value.toUpperCase()) {
          region = { value: value.toUpperCase(), label: value.toUpperCase() };

          if (!countrySet.has(i.country.toUpperCase())) {
            countryOptions.push({
              value: i.country,
              label: i.country,
            });
            countrySet.add(i.country.toUpperCase());
          }

          if (!branchSet.has(i.location_zone.toUpperCase())) {
            branchOptions.push({
              value: i.location_zone,
              label: i.location_zone,
            });
            branchSet.add(i.location_zone.toUpperCase());
          }

          if (!dcSet.has(i.location_code)) {
            dcOptions.push({
              value: i.location_code,
              label: i.location_name,
            });
            dcSet.add(i.location_code);
          }
        }
      } else {
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          });
          countrySet.add(i.country);
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone);
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      }
    }

    this.setState({
      region: region,
      countries: countryOptions,
      locationZones: branchOptions,
      locationNames: dcOptions,
    });
  };

  fetchOptionsForCountry = (value) => {
    // console.log(value, "country first");

    let region = { value: "All", label: "All" };
    let regionOptions = [{ value: "All", label: "All" }];
    let countryOptions = [{ value: "All", label: "All" }];
    let branchOptions = [{ value: "All", label: "All" }];
    let dcOptions = [{ value: "All", label: "All" }];
    let country = { value: "All", label: "All" };

    let regionSet = new Set();
    let countrySet = new Set();
    let branchSet = new Set();
    let dcSet = new Set();
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        location_access.includes(item.location_code)
      );
    }
    for (let i of boundLocations) {
      if (value !== "All") {
        if (i.country && i.country === value) {
          region = {
            value: i.region.toUpperCase(),
            label: i.region.toUpperCase(),
          };
          country = {
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          };

          if (!regionSet.has(i.region)) {
            regionOptions.push({
              value: i.region,
              label: i.region,
            });
            regionSet.add(i.region);
          }

          if (!branchSet.has(i.location_zone.toUpperCase())) {
            branchOptions.push({
              value: i.location_zone,
              label: i.location_zone,
            });
            branchSet.add(i.location_zone.toUpperCase());
          }

          if (!dcSet.has(i.location_code)) {
            dcOptions.push({
              value: i.location_code,
              label: i.location_name,
            });
            dcSet.add(i.location_code);
          }
        }
        if (!countrySet.has(i.country)) {
          countryOptions.push({
            value: i.country,
            label: i.country,
          });
          countrySet.add(i.country);
        }
      } else {
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          });
          countrySet.add(i.country.toUpperCase());
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      }
    }

    this.setState({
      region: region,
      country: country,
      regions: regionOptions,
      countries: countryOptions,
      locationZones: branchOptions,
      locationNames: dcOptions,
    });
  };

  fetchOptionsForBranch = (value) => {
    console.log(value, "branch");

    let country = { value: "All", label: "All" };
    let region = { value: "All", label: "All" };
    let branch = { value: "All", label: "All" };
    let regionOptions = [{ value: "All", label: "All" }];
    let countryOptions = [{ value: "All", label: "All" }];
    let branchOptions = [{ value: "All", label: "All" }];
    let dcOptions = [{ value: "All", label: "All" }];

    let regionSet = new Set();
    let branchSet = new Set();
    let countrySet = new Set();
    let dcSet = new Set();
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        location_access.includes(item.location_code)
      );
    }
    for (let i of boundLocations) {
      if (value !== "All") {
        if (
          i.location_zone.toUpperCase() &&
          i.location_zone.toUpperCase() === value.toUpperCase()
        ) {
          branch = {
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          };
          region = { value: i.region, label: i.region };
          country = {
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          };
          if (!dcSet.has(i.location_code)) {
            dcOptions.push({
              value: i.location_code,
              label: i.location_name,
            });
            dcSet.add(i.location_code);
          }
        }
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country,
            label: i.country,
          });
          countrySet.add(i.country.toUpperCase());
        }
      } else {
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          });
          countrySet.add(i.country.toUpperCase());
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      }
    }

    this.setState({
      region: region,
      zone: branch,
      country: country,
      regions: regionOptions,
      countries: countryOptions,
      locationZones: branchOptions,
      locationNames: dcOptions,
    });
  };

  fetchOptionsForLocation = (value) => {
    // console.log(value, "location first");

    let country = { value: "All", label: "All" };
    let region = { value: "All", label: "All" };
    let branch = { value: "All", label: "All" };
    let dcOption = { value: "All", label: "All" };
    let regionOptions = [{ value: "All", label: "All" }];
    let countryOptions = [{ value: "All", label: "All" }];
    let branchOptions = [{ value: "All", label: "All" }];
    let dcOptions = [{ value: "All", label: "All" }];

    let regionSet = new Set();
    let countrySet = new Set();
    let branchSet = new Set();
    let dcSet = new Set();
    let location_access = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (location_access !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        location_access.includes(item.location_code)
      );
    }
    for (let i of boundLocations) {
      if (value !== "All") {
        if (
          i.location_code &&
          i.location_code.toUpperCase() === value.toUpperCase()
        ) {
          branch = {
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          };
          region = { value: i.region, label: i.region };
          country = {
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          };
          dcOption = { value: value, label: i.location_name };
        }
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country,
            label: i.country,
          });
          countrySet.add(i.country.toUpperCase());
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone,
            label: i.location_zone,
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      } else {
        if (!regionSet.has(i.region)) {
          regionOptions.push({
            value: i.region,
            label: i.region,
          });
          regionSet.add(i.region);
        }
        if (!countrySet.has(i.country.toUpperCase())) {
          countryOptions.push({
            value: i.country.toUpperCase(),
            label: i.country.toUpperCase(),
          });
          countrySet.add(i.country.toUpperCase());
        }
        if (!branchSet.has(i.location_zone.toUpperCase())) {
          branchOptions.push({
            value: i.location_zone.toUpperCase(),
            label: i.location_zone.toUpperCase(),
          });
          branchSet.add(i.location_zone.toUpperCase());
        }
        if (!dcSet.has(i.location_code)) {
          dcOptions.push({
            value: i.location_code,
            label: i.location_name,
          });
          dcSet.add(i.location_code);
        }
      }
    }
    this.setState({
      country: country,
      region: region,
      zone: branch,
      location: dcOption,
      regions: regionOptions,
      countries: countryOptions,
      locationZones: branchOptions,
      locationNames: dcOptions,
    });
  };
  getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  materialDetailsInputFields = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };

  onClickGetData = async () => {
    if (true) {
      // if (this.state.dailyData === true) {
      // if (this.state.location.value !== "All" && this.state.invoicedate) {
      await this.getDataForNewDashboard();
      // } else {
      //   this.setState({
      //     show: true,
      //     basicTitle: "Select Pickup Location",
      //     basicType: "danger",
      //   });
      // }
    } else {
      // if (this.state.location.value !== "All" && this.state.invoicedate) {
      await this.getoutputDetailsForGrid();
      await this.getDataForNewDashboard();
      // } else {
      //   this.setState({
      //     show: true,
      //     basicTitle: "Select Pickup Location",
      //     basicType: "danger",
      //   });
      // }
    }
  };
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  }

  hideSlideBlock = () => {
    this.setState({
      overlyRing: "show-n",
      overly: "show-n",
      overlayBlock: "show-n",
    });
  };

  closeAlert = () => {
    this.setState({
      show: false,
      loadshow: "show-n",
      overly: "show-n",
    });
    // window.location.reload()
  };

  onClickTab = async (pageType) => {
    var tabName = pageType;
    var dailychartsTab = "activet";
    var dailydataTab = "";
    var dailychartsData = true;
    var dailyData = false;
    if (pageType === "dailychartsData") {
      dailychartsTab = "activet";
      dailydataTab = "";
      dailychartsData = true;
      dailyData = false;
      await this.getDataForNewDashboard();
    } else if (pageType === "dailyData") {
      dailychartsTab = "";
      dailydataTab = "activet";
      dailychartsData = false;
      dailyData = true;
      await this.getDataForNewDashboard();
    }
    this.setState({
      dailychartsTab: dailychartsTab,
      dailydataTab: dailydataTab,

      dailychartsData: dailychartsData,
      dailyData: dailyData,
    });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary charts screen ",
      activity: `Clicked on  ${tabName} tab`,
      event: `Navigated to ${tabName} screen `,
      data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onChangeDispatchType = (dispatchType) => {
    // var { name, value } = event.target;
    // console.log("first eve", dispatchType);
    this.setState({ dispatchType });
  };
  render() {
    const { plantLocation } = this.props;

    var { chartData } = this.state;
    var categories = [];
    var plandTripDist = { dedicated: [], spot: [], total: [] },
      plndTripCount = { dedicated: [], spot: [], total: [] },
      plndVfr = { dedicated: [], spot: [], total: [] },
      asgndTripVfr = { dedicated: [], spot: [], total: [] },
      assgnTripCount = { dedicated: [], spot: [], total: [] },
      asgndTripDist = { dedicated: [], spot: [], total: [] },
      tripCost = { dedicated: [], spot: [], total: [] },
      plangPercentage = { dedicated: [], spot: [], total: [] };

    let TplanTripCount = 0;
    let TassignedTripCount = 0;
    let TplannedVFR = 0;
    let TassignedVFR = 0;
    let TplannedTripDistance = 0;
    let TassignedTripDistance = 0;
    let TplannedPercentage = 0;
    let Ttripcost = 0;

    if (chartData.length > 0) {
      console.log(chartData, "chartDataa");
      chartData.map((item) => {
        var plantName = Object.keys(item)[0];
        categories.push(plantName);
        // console.log("first", Object.keys(item));

        plndTripCount.dedicated.push(
          item[plantName].planned_trips_count.dedicated_count
        );
        plndTripCount.spot.push(item[plantName].planned_trips_count.spot_count);
        plndTripCount.total.push(
          item[plantName].planned_trips_count.overall_count
        );
        TplanTripCount += item[plantName].planned_trips_count.overall_count;

        assgnTripCount.dedicated.push(
          item[plantName].assigned_trip_count.dedicated_count
        );
        assgnTripCount.spot.push(
          item[plantName].assigned_trip_count.spot_count
        );
        assgnTripCount.total.push(
          item[plantName].assigned_trip_count.overall_count
        );
        TassignedTripCount += item[plantName].assigned_trip_count.overall_count;

        plndVfr.dedicated.push(item[plantName].planned_vfr.dedicated_vfr);
        plndVfr.spot.push(item[plantName].planned_vfr.spot_vfr);
        plndVfr.total.push(item[plantName].planned_vfr.overall_vfr);
        TplannedVFR += item[plantName].planned_vfr.overall_vfr;

        asgndTripVfr.dedicated.push(item[plantName].assigned_vfr.dedicated_vfr);
        asgndTripVfr.spot.push(item[plantName].assigned_vfr.spot_vrf);
        asgndTripVfr.total.push(item[plantName].assigned_vfr.overal_vfr);
        TassignedVFR += item[plantName].assigned_vfr.overal_vfr;

        plandTripDist.dedicated.push(
          item[plantName].planned_trip_distance.dedicated_distance
        );
        plandTripDist.spot.push(
          item[plantName].planned_trip_distance.spot_planned_distance
        );
        plandTripDist.total.push(
          item[plantName].planned_trip_distance.total_distance
        );
        TplannedTripDistance +=
          item[plantName].planned_trip_distance.total_distance;

        asgndTripDist.dedicated.push(
          item[plantName].assigned_trip_distance.dedicated_distance
        );
        asgndTripDist.spot.push(
          item[plantName].assigned_trip_distance.spot_distance
        );
        asgndTripDist.total.push(
          item[plantName].assigned_trip_distance.total_distance
        );
        TassignedTripDistance +=
          item[plantName].assigned_trip_distance.total_distance;

        plangPercentage.dedicated.push(
          parseFloat(
            item[
              plantName
            ].planning_percentage.dedicated_planning_percentage.toFixed(2)
          )
        );
        plangPercentage.spot.push(
          parseFloat(
            item[
              plantName
            ].planning_percentage.spot_planning_percentaeg.toFixed(2)
          )
        );
        plangPercentage.total.push(
          parseFloat(
            item[
              plantName
            ].planning_percentage.overall_planning_percentage.toFixed(2)
          )
        );
        TplannedPercentage +=
          item[plantName].planning_percentage.overall_planning_percentage;

        tripCost.dedicated.push(item[plantName].trip_cost.dedicated_cost);
        tripCost.spot.push(item[plantName].trip_cost.spot_cost);
        tripCost.total.push(item[plantName].trip_cost.overall_cost);
        Ttripcost += item[plantName].trip_cost.overall_cost;

        return true;
      });
    }
    // console.log(
    //   TplannedVFR / chartData.length,
    //   TassignedVFR / chartData.length,
    //   TplannedPercentage / chartData.length,
    //   "XXXXXXXXXXXXXXXXXXXXX first"
    // );
    var plandTripDistSeries = [
      { name: "Total", data: plandTripDist.total },
      { name: "Dedicated", data: plandTripDist.dedicated },
      { name: "Spot", data: plandTripDist.spot },
    ];

    var plangPercentageSeries = [
      { name: "Total", data: plangPercentage.total },
      { name: "Dedicated", data: plangPercentage.dedicated },
      { name: "Spot", data: plangPercentage.spot },
    ];

    var tripCostSeries = [
      { name: "Total", data: tripCost.total },
      { name: "Dedicated", data: tripCost.dedicated },
      { name: "Spot", data: tripCost.spot },
    ];

    var asgnddTripCountSeries = [
      { name: "Total", data: assgnTripCount.total },
      { name: "Dedicated", data: assgnTripCount.dedicated },
      { name: "Spot", data: assgnTripCount.spot },
    ];

    var plndTripCountSerires = [
      { name: "Total", data: plndTripCount.total },
      { name: "Dedicated", data: plndTripCount.dedicated },
      { name: "Spot", data: plndTripCount.spot },
    ];

    var plndVfrSeries = [
      { name: "Total", data: plndVfr.total },
      { name: "Dedicated", data: plndVfr.dedicated },
      { name: "Spot", data: plndVfr.spot },
    ];

    var asgndTripDistSeries = [
      { name: "Total", data: asgndTripDist.total },
      { name: "Dedicated", data: asgndTripDist.dedicated },
      { name: "Spot", data: asgndTripDist.spot },
    ];

    var asgndVfrSeries = [
      { name: "Total", data: asgndTripVfr.total },
      { name: "Dedicated", data: asgndTripVfr.dedicated },
      { name: "Spot", data: asgndTripVfr.spot },
    ];
    // console.log("first", categories, plandTripDist);
    var planSummarycolsDefs = [
      {
        headerName: "Transaction Id",
        field: "transaction_id",
        width: 170,
      },
      {
        headerName: "Trip No",
        field: "trip_no",
        width: "60",
        cellClass: "text-center",
      },
      // {
      //   headerName: "Planned Day",
      //   headerTooltip: "Planned Day",
      //   field: "drop_node_total_time_day",
      //   width: "100",
      //   valueGetter: function (params) {
      //     try {
      //       if (
      //         params.data.drop_node_total_time_day !== "" &&
      //         params.data.drop_node_total_time_day !== undefined
      //       ) {
      //         var a = parseInt(params.data.drop_node_total_time_day);
      //         return "Day - " + (a + 1);
      //       } else {
      //         return params.data.drop_node_total_time_day;
      //       }
      //     } catch (error) {}
      //   },
      // },
      {
        headerName: "Vehicle No",
        field: "vehicle_no",
        width: "120",
        cellRendererSelector: function (params) {
          try {
            var rendComponent = {
              component: "RepeatIcon",
            };
            return rendComponent;
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: "100",
      },
      {
        headerName: "Planned Trip Type",
        field: "planned_trip_type",
        width: "100",
      },
      {
        headerName: "Cost (₹)",
        field: "cost",
        width: "100",
        //   hide:showCost
      },
      // {
      //   headerName: "Drop Sequence",
      //   headerTooltip: "Drop Sequence",
      //   field: "drop_sequence",
      //   width: "100",
      // },
      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "120",
      },
      {
        headerName: "Invoicing Date",
        headerTooltip: "Invoicing Date",
        field: "invoicing_date",
        width: "130",
        valueGetter: (params) => {
          if (params.data.invoicing_date) {
            return moment
              .parseZone(params.data.invoicing_date)
              .format("YYYY-MM-DD");
          }
        },
      },
      // {
      //     headerName:"Transaporter Code",
      //     field:"transporter_code",
      //     width:"150"
      // },
      // {
      //     headerName:"Transaporter Name",
      //     field:"transporter_name",
      //     width:"150"
      // },
      // {
      //     headerName:"Route Mode",
      //     field:"route_mode",
      //     width:"120"
      // },
      {
        headerName: "Plant Location Code",
        field: "pickup_node",
        width: "60",
      },
      // {
      //   headerName: "Drop Location Code (Destination)",
      //   headerTooltip: "Drop Location Code (Destination)",
      //   field: "drop_node",
      //   width: "230",
      // },
      // {
      //   headerName: "Drop Location Name (Destination)",
      //   headerTooltip: "Drop Location Name (Destination)",
      //   field: "drop_node_name",
      //   width: "250",
      // },
      {
        headerName: "Destination Location City",
        headerTooltip: "Destination Location City",
        field: "demand_node_location",
        width: "100",
      },
      {
        headerName: "Destination Location State",
        headerTooltip: "Destination Location State",
        field: "demand_node_state",
        width: "123",
      },
      // {
      //     headerName:"Vehicle Max Volume(M³)",
      //     field:"vehicle_max_volume",
      //     width:160
      // },
      // {
      //     headerName:"Vehicle Max Weight(Kgs)",
      //     field:"vehicle_max_weight",
      //     width:160
      // },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "120",
        valueGetter: (params) => {
          try {
            if (
              params.data.quantity !== undefined &&
              params.data.quantity !== ""
            ) {
              return params.data.quantity.toFixed(2);
            } else {
              return "N/A";
            }
          } catch {}
        },
      },
      {
        headerName: "Volume (M³)",
        headerTooltip: "Volume (M³)",
        field: "demand_volume",
        width: "140",
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "demand_weight",
        width: "120",
      },
      // {
      //     headerName:"Distance From Pickup Location (Kms)",
      //     headerTooltip:"Distance From Pickup Location (Kms)",
      //     field:"distance",
      //     width:"200",
      // },
      // {
      //   headerName: "Distance To Destination (Kms)",
      //   headerTooltip: "Distance To Destination (Kms)",
      //   field: "distance",
      //   width: "240",
      // },
      {
        headerName: "Expected Delivery Date",
        field: "delivery_date",
        width: "150",
      },
      // {
      //     headerName:"Time From Pickup Location (HH:MM)",
      //     headerTooltip:"Time From Pickup Location (HH:MM)",
      //     field:"drop_node_total_time_hrs",
      //     width:"200",
      // },
      // {
      //   headerName: "Time To Destination",
      //   headerTooltip: "Time To Destination",
      //   field: "drop_node_total_time_hrs",
      //   width: "190",
      // },
      {
        headerName: "Transit Time (HH:MM)",
        headerTooltip: "Transit Time (HH:MM)",
        field: "drop_node_transit_time_hrs",
        width: "150",
      },
      {
        headerName: "Actual Trip Cost",
        headerTooltip: "Actual Trip Cost",
        field: "actual_cost",
        width: "100",
      },
      // {
      //   headerName: "Actual Dedicated Cost",
      //   headerTooltip: "Actual Dedicated Cost",
      //   field: "actual_dedicated_cost",
      //   width: "150",
      // },
      // {
      //   headerName: "Loading Time (HH:MM)",
      //   headerTooltip: "Loading Time (HH:MM)",
      //   field: "drop_node_loading_time_mins",
      //   width: "190",
      // },
      // {
      //   headerName: "Unloading Time (HH:MM)",
      //   headerTooltip: "Unloading Time (HH:MM)",
      //   field: "drop_node_unloading_time_mins",
      //   width: "190",
      // },
      // {
      //     headerName:"Distance From Previous Location (Kms)",
      //     field:"distance_from_prev_location",
      //     width:"200",
      // },
      // {
      //     headerName:"Time From Previous Location (HH:MM)",
      //     field:"time_from_prev_location",
      //     width:"200",
      // },
      // {
      //     headerName:"Drop Location City",
      //     field:"demand_node_location",
      //     width:"120",
      // },
      // {
      //     headerName:"Zone",
      //     field:"zone",
      //     width:"100"
      // },
      // {
      //     headerName:"Drop Location (Destination)",
      //     field:"drop_node",
      //     width:"150"
      // },
      // {
      //     headerName:"Vehicle Volume",
      //     field:"vehicle_volume",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Priority",
      //     field:"vehicle_priority",
      //     width:"120"
      // },
    ];
    var dispatchTypes = [
      { label: "All", value: "All" },
      { label: "Secondary", value: "SECONDARY" },
      { label: "Primary", value: "PRIMARY" },
    ];

    var sarOptions = [{ value: "", label: "All" }],
      countries = [];
    var branchOptions = [{ value: "", label: "All" }],
      regions = [];
    var pickupOptions = [{ value: "", label: "All" }],
      pickupCodes = [];
    var dispatchTypes = [
      { label: "All", value: "All" },
      { label: "Secondary", value: "SECONDARY" },
      { label: "Primary", value: "PRIMARY" },
    ];
    var dispatchCodes = [];
    var { dispatchType, country, zone, location } = this.state;
    var boundLocations = this.state.boundLocations;
    var locationAccess = JSON.parse(localStorage.getItem("location_access"));
    var boundLocations = this.state.boundLocations;
    if (locationAccess !== null) {
      boundLocations = this.state.boundLocations.filter((item) =>
        locationAccess.includes(item.location_code)
      );
    }
    if (dispatchType.value !== "" && dispatchType.value !== "All") {
      boundLocations = boundLocations.filter(
        (item) => item.dispatch_type === dispatchType.value
      );
    }

    boundLocations.map((item) => {
      if (!countries.includes(item.country)) {
        sarOptions.push({ value: item.country, label: item.country });
        countries.push(item.country);
      }
    });
    if (country.value !== "" && country.value !== "All") {
      boundLocations = boundLocations.filter(
        (item) => item.country === country.value
      );
    }
    boundLocations.map((item) => {
      if (!regions.includes(item.location_zone)) {
        branchOptions.push({
          value: item.location_zone,
          label: item.location_zone,
        });
        regions.push(item.location_zone);
      }
    });
    // console.log("first 1", boundLocations, country, zone);
    if (zone.value !== "" && zone.value !== "All") {
      boundLocations = boundLocations.filter(
        (item) => item.location_zone === zone.value
      );
    }
    boundLocations.map((item) => {
      if (!pickupCodes.includes(item.location_code)) {
        pickupOptions.push({
          value: item.location_code,
          label: item.location_name,
        });
        pickupCodes.push(item.location_code);
      }
    });
    if (location.value !== "" && location.value !== "All") {
      var loc = boundLocations.filter(
        (item) => item.location_code === location.value
      );
      branchOptions = [{ value: "", label: "All" }];
      sarOptions = [{ value: "", label: "All" }];
      dispatchTypes = [{ value: "", label: "All" }];
      loc.map((item) => {
        branchOptions.push({
          value: item.location_zone,
          label: item.location_zone,
        });
        sarOptions.push({
          value: item.country,
          label: item.country,
        });
        dispatchTypes.push({
          value: item.dispatch_type,
          label: item.dispatch_type,
        });
      });
    }
    if (zone.value !== "" && zone.value !== "All") {
      var loc = boundLocations.filter(
        (item) => item.location_zone === zone.value
      );
      sarOptions = [{ value: "", label: "All" }];
      var dispatchCodes = [];

      // if (dispatchType.value === "All" || dispatchType.value === "") {
      dispatchTypes = [{ value: "", label: "All" }];
      // }
      dispatchTypes.map((item) => {
        dispatchCodes.push(item.value);
      });
      var sarCodes = [];
      loc.map((item) => {
        if (!sarCodes.includes(item.country)) {
          sarCodes.push(item.country);
          sarOptions.push({
            value: item.country,
            label: item.country,
          });
        }
        if (!dispatchCodes.includes(item.dispatch_type)) {
          dispatchCodes.push(item.dispatch_type);
          dispatchTypes.push({
            value: item.dispatch_type,
            label: item.dispatch_type,
          });
        }
      });
    }
    if (country.value !== "" && country.value !== "All") {
      var loc = boundLocations.filter((item) => item.country === country.value);
      var dispatchCodes = [];

      // if (dispatchType.value === "All" || dispatchType.value === "") {
      dispatchTypes = [{ value: "", label: "All" }];
      // }
      dispatchTypes.map((item) => {
        dispatchCodes.push(item.value);
      });
      loc.map((item) => {
        if (!dispatchCodes.includes(item.dispatch_type)) {
          dispatchCodes.push(item.dispatch_type);
          dispatchTypes.push({
            value: item.dispatch_type,
            label: item.dispatch_type,
          });
        }
      });
    }
    // console.log(boundLocations, "first", sarOptions);

    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="row">
          <div className="col-sm-4 d-flex d-inline mtop-30p align-items-center">
            <ul className="d-tabs">
              <li
                onClick={this.onClickTab.bind(this, "dailychartsData")}
                className={"lttabs " + this.state.dailychartsTab}
              >
                <button type="button" className="btn mt-30p">
                  Summary{" "}
                </button>
              </li>

              <li
                onClick={this.onClickTab.bind(this, "dailyData")}
                className={"lttabs " + this.state.dailydataTab}
              >
                <button type="button" className="btn">
                  Data
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-2">
            <div className="form-group ">
              <label className="col-form-label f12">Region</label>
              <Select
                placeholder={"Select"}
                onChange={this.onChangeRegion}
                name="state"
                value={this.state.region}
                options={this.st  ate.regions}
              />
            </div>
          </div> */}

          <div className="col">
            <div className="form-group ">
              <label className="col-form-label f12">Dispatch Type</label>
              <Select
                placeholder={"Select"}
                onChange={this.onChangeDispatchType}
                name="dispatchType"
                value={this.state.dispatchType}
                options={dispatchTypes}
              />
            </div>
          </div>

          <div className="col">
            <div className="form-group ">
              <label className="col-form-label f12">SAR</label>
              <Select
                placeholder={"Select"}
                onChange={this.locationCountryChange}
                name="country"
                value={this.state.country}
                options={sarOptions}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group ">
              <label className="col-form-label f12">Branch</label>
              <Select
                placeholder={"Select"}
                onChange={this.locationZoneChange}
                name="zone"
                value={this.state.zone}
                options={branchOptions}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group ">
              <label className="col-form-label f12">Pickup Location</label>
              <Select
                placeholder={"Select"}
                onChange={this.locationChanged}
                name="location"
                value={this.state.location}
                options={pickupOptions}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group ">
              <label className="col-form-label f12">Invoicing Date</label>
              <input
                type="date"
                className="form-control"
                autoComplete="off"
                name="invoicedate"
                value={this.state.invoicedate}
                onChange={this.materialDetailsInputFields}
                onKeyDown={(e) => e.preventDefault()}
                max={this.getCurrentDateTime()}
                readonly
              />
            </div>
          </div>
          <div className="col">
            <button
              type="button"
              onClick={this.onClickGetData}
              className="btn btn-warning mt-30px"
              style={{ marginTop: "35px" }}
            >
              Get Data
            </button>
          </div>
        </div>
        {this.state.dailychartsData === true ? (
          <div className="row p-1">
            {/* <div className="row counters-row"> */}
            <div className="counter-column col p-1 text-center">
              <div className="o-hidden card inner-con">
                <div className={"bg b-r-4 card-body p-3 "}>
                  <div className="media static-top-widget d-flex">
                    <div className="align-self-center text-center">
                      <i className={`icofont icofont-truck f32 blackfont`}></i>
                    </div>
                    <div className="media-body" style={{ padding: "10px" }}>
                      <h4
                        className="counter mb-0 f28 d-flex justify-content-center"
                        style={{ fontFamily: "Poppins-Regular" }}
                      >
                        <CountUp
                          end={TplanTripCount != "" ? TplanTripCount : 0}
                        />
                      </h4>
                      <span
                        className="m-0 d-flex justify-content-center"
                        style={{ minHeight: "40px" }}
                      >
                        Total Planned Trips
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="counter-column col p-1 text-center">
              <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                  <div class="media static-top-widget d-flex">
                    <div class="align-self-center text-center">
                      <i className={"icofont icofont-clock-time f32 "}></i>
                    </div>
                    <div class="media-body" style={{ padding: "10px" }}>
                      <h4
                        class="counter mb-0 f28 d-flex justify-content-center"
                        style={{ fontFamily: "Poppins-Regular" }}
                      >
                        <span>
                          <CountUp
                            end={
                              TassignedTripCount != "" ? TassignedTripCount : 0
                            }
                          />
                        </span>
                      </h4>
                      <span
                        class="ml-3 d-flex justify-content-center"
                        style={{ minWidth: "100px", minHeight: "40px" }}
                      >
                        Total Assigned Trips
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="counter-column col p-1 text-center">
              <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                  <div class="media static-top-widget d-flex">
                    <div class="align-self-center text-center">
                      <i className={"icofont icofont-clock-time f32 "}></i>
                    </div>
                    <div class="media-body" style={{ padding: "10px" }}>
                      <h4
                        class="counter mb-0 f28 d-flex justify-content-center"
                        style={{ fontFamily: "Poppins-Regular" }}
                      >
                        <span>
                          <CountUp
                            end={
                              TplannedVFR !== ""
                                ? TplannedVFR / chartData.length
                                : 0
                            }
                            decimals={2}
                          />
                        </span>
                      </h4>
                      <span
                        class="ml-1 d-flex justify-content-center"
                        style={{
                          textAlign: "center",
                          minWidth: "120px",
                          minHeight: "40px",
                        }}
                      >
                        Avg Planned VFR%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="counter-column col p-1 text-center">
              <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                  <div class="media static-top-widget d-flex">
                    <div class="align-self-center text-center">
                      <i className={"icofont icofont-clock-time f32 "}></i>
                    </div>
                    <div class="media-body" style={{ padding: "10px" }}>
                      <h4
                        class="counter mb-0 f28 d-flex justify-content-center"
                        style={{ fontFamily: "Poppins-Regular" }}
                      >
                        <span>
                          <CountUp
                            end={
                              TassignedVFR != ""
                                ? TassignedVFR / chartData.length
                                : 0
                            }
                            decimals={2}
                          />
                        </span>
                      </h4>
                      <span
                        class="m-0 d-flex justify-content-center"
                        style={{ minHeight: "40px" }}
                      >
                        Avg Assigned VFR %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="counter-column col p-1 text-center">
              <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                  <div class="media static-top-widget d-flex">
                    <div class="align-self-center text-center">
                      <i className={"icofont icofont-pause f32 "}></i>
                    </div>
                    <div class="media-body" style={{ padding: "10px" }}>
                      <h4
                        class="counter mb-0 f28 d-flex justify-content-center"
                        style={{ fontFamily: "Poppins-Regular" }}
                      >
                        <span>
                          <CountUp
                            end={
                              TplannedTripDistance ? TplannedTripDistance : 0
                            }
                            decimals={2}
                          />
                        </span>
                      </h4>
                      <span
                        class="m-0 d-flex justify-content-center"
                        style={{ minHeight: "40px" }}
                      >{`Total Planned Distance (kms)`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="counter-column col p-1 text-center">
              <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                  <div class="media static-top-widget d-flex">
                    <div class="align-self-center text-center">
                      <i className={"icofont icofont-pause f32 "}></i>
                    </div>
                    <div class="media-body" style={{ padding: "10px" }}>
                      <h4
                        class="counter mb-0 f28 d-flex justify-content-center"
                        style={{ fontFamily: "Poppins-Regular" }}
                      >
                        <span>
                          <CountUp
                            end={
                              TassignedTripDistance != ""
                                ? TassignedTripDistance
                                : 0
                            }
                            decimals={2}
                          />
                        </span>
                      </h4>
                      <span
                        class="m-0 d-flex justify-content-center"
                        style={{ minHeight: "40px" }}
                      >{`Total Assigned Distance (kms)`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="counter-column col p-1 text-center">
              <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                  <div class="media static-top-widget d-flex">
                    <div class="align-self-center text-center">
                      <i className={"icofont icofont-pause f32 "}></i>
                    </div>
                    <div class="media-body" style={{ padding: "10px" }}>
                      <h4
                        class="counter mb-0 f28 d-flex justify-content-center"
                        style={{ fontFamily: "Poppins-Regular" }}
                      >
                        <span>
                          <CountUp
                            end={
                              TplannedPercentage != ""
                                ? TplannedPercentage / chartData.length
                                : 0
                            }
                            decimals={2}
                          />
                        </span>
                      </h4>
                      <span
                        class="ml-1 d-flex justify-content-center"
                        style={{
                          textAlign: "center",
                          minWidth: "120px",
                          minHeight: "40px",
                        }}
                      >
                        Total Planning (%)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="counter-column col p-1 text-center">
              <div class="o-hidden card inner-con">
                <div class={"bg b-r-4 card-body p-3 "}>
                  <div class="media static-top-widget d-flex">
                    <div class="align-self-center text-center">
                      <i className={"icofont icofont-pause f32 "}></i>
                    </div>
                    <div class="media-body" style={{ padding: "10px" }}>
                      <h4
                        class="counter mb-0 f28 d-flex justify-content-center"
                        style={{ fontFamily: "Poppins-Regular" }}
                      >
                        <span>
                          <CountUp
                            end={Ttripcost !== "" ? Ttripcost : 0}
                            decimals={2}
                          />
                        </span>
                      </h4>
                      <span
                        class="ml-1 d-flex justify-content-center"
                        style={{
                          textAlign: "center",
                          minWidth: "120px",
                          minHeight: "40px",
                        }}
                      >
                        Total Assigned Trip Cost (Rs)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Repeat for other counters */}
            {/* </div> */}
          </div>
        ) : (
          ""
        )}
        <div
          className={
            "row mt-5p " + (this.state.dailyData === true ? "show-m" : "show-n")
          }
        >
          <div></div>
          <div
            id="myGrid1"
            style={{ width: "100%", height: "90vh" }}
            className="col-sm-12 ag-theme-balham dropdown"
          >
            <div
              style={{
                fontSize: "12px",
                color: "#ff0000",
                // textAlign: "right",
                marginTop: "2px",
              }}
            >
              <p>
                <b>Note</b>: 1. Actual cost information will reflect against
                vehicle assigned tripafter successful closure of trip w.r.t
                Vehicle GPS tracking. 2.Cost will not reflect for spot vehicle
                plans.
              </p>
            </div>
            <AgGridReact
              // modules={this.state.modules}
              rowData={this.state.rowdata}
              columnDefs={planSummarycolsDefs}
              gridOptions={{ context: { componentParent: this } }}
              defaultColDef={this.state.defaultColDef}
              frameworkComponents={this.state.frameworkComponents}
              paginationPageSize={this.state.paginationPageSize}
              pagination={true}
              getRowStyle={this.getRowStyle}
              enableRangeSelection={true}
              sideBar={this.state.sideBar}
              statusBar={this.state.statusBar}
              enablePivot={true}
              enableCharts={true}
            />
          </div>
        </div>

        {this.state.dailychartsTab ? (
          <>
            <div className={"row mt-2" + ""}>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={plndTripCountSerires}
                  title={"Planned Trip Count"}
                  yaxis={"Trip Count"}
                />
              </div>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={asgnddTripCountSeries}
                  title={"Assigned Trip Count"}
                  yaxis={"Trip Count"}
                />
              </div>
            </div>

            <div className={"row mt-5" + ""}>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={plndVfrSeries}
                  title={"Planned VFR (%)"}
                  yaxis={"Avg VFR (%)"}
                />
              </div>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={asgndVfrSeries}
                  title={"Assigned VFR (%)"}
                  yaxis={"Avg VFR (%)"}
                />
              </div>
            </div>
            <div className={"row mt-5" + ""}>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={plandTripDistSeries}
                  title={"Planned Trip Distance"}
                  suffix={"Kms"}
                  yaxis={"Trip Distance (Kms)"}
                />
              </div>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={asgndTripDistSeries}
                  title={"Assigned Trip Distance "}
                  suffix={"Kms"}
                  yaxis={"Trip Distance (Kms)"}
                />
              </div>
            </div>

            <div className={"row mt-5" + ""}>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={plangPercentageSeries}
                  title={"Planning (%)"}
                  yaxis={"Planning (%)"}
                />
              </div>
              <div className="col-md-6">
                <BarChart
                  categories={categories}
                  series={tripCostSeries}
                  title={"Trip Cost"}
                  yaxis={"Trip Cost (Rs)"}
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.onClickHideAll.bind(this)}
        ></div>
        <div className={"loader " + this.state.loadshow}></div>
      </>
    );
  }
}
