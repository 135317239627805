import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  getDDMMYYYYHHMMSS,
  getHyphenDDMMMYYYYHHMM,
  getYYYYMMDDHHMMSS,
  getHyphenYYYYMMDDHHMMSS,
} from "./common/utils";
import showTruckLocationComp from "./manage/showTruckLocationComp";
import CustomLoadingCellRenderer from "./layouts/customLoadingCellRenderer";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import $ from "jquery";

import DrawMap from "../components/common/drawmap";
// import Trucklocaction from './trucklocaction';
import redirectURL from "./redirectURL";
var infoBox = require("./common/google-infowindow");
var moment = require("moment");
// var googleAnalytics = require("../common/googleanalytics");

var map;
var viewMarkersArr = [];
export default class PlantDataPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultRecords: [],
      plantDetails: [],
      sliderRouteTranslate: "",
      pageTitle: "Plants Panel Data",
      eventGridAction: "gridAction",
      eventCounterAction: "counterAction",
      eventFormAction: "formAction",
      routemap: "",
      allmarkers: "",
      trucks: [],
      startDate: moment.parseZone().subtract(1, "months").format("YYYY-MM-DD"),
      endDate: moment.parseZone().format("YYYY-MM-DD"),

      truck_no: "",
      selected_truck_no: "",
      // startDate:'',
      // endDate:'',
      mapinfo: { coords: [], breaks: [] },
      mapinfoclick: "",
      modules: AllModules,
      defTransitCoords: "",
      activeMarker: {},
      plant_name: { value: "", label: "All" },
      selectedPlace: {},
      dealerCode: "",
      timelinesmarkers: [],
      viewtimelinemarkers: true,
      viewgoogleroutes: true,
      googleroutepath: [],
      loadshow: "show-n",
      open: false,
      alert: null,
      show: false,
      basicTitle: "",
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 70,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      gridshow: "show-m",
      filteredData: [],
      mapshow: "show-m",
      actgridbtn: "btn-success",
      actmapbtn: "btn-default",
      rowData: null,
      showTrucksList: "show-n",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      overlayNoRowsTemplate: "",
      frameworkComponents: {
        customLoadingCellRenderer: CustomLoadingCellRenderer,
        //   trucklocaction:Trucklocaction
        showTruckLocationComp: showTruckLocationComp,
      },

      animateRows: true,
      debug: true,
      showToolPanel: false,
      uppressAggFuncInHeader: true,

      childconfs: [],
      childrow: [],
      rowModelType: "serverSide",
      paginationPageSize: 50,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
      plants_list: [{ value: "", label: "All" }],
      overly: "show-n",
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      detailCellRendererParams: {},
      rowClassRules: {
        highlitRow: "data.transit_delay == 1",
        green: "data.status >= 3",
      },
      tolls: [],
      frequency: "",
      showmarkers: 0,
      maptruck: "",
      tottalDistance: [],
      totaldistanceval: 0,
      avg_temp: 0,
      truckNo: "",
    };
    this.setTruckno = this.setTruckno.bind(this);
    this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
    // this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
  }
  logPageView = () => {
    // try {
    //     if (googleAnalytics.page.enableGA) {
    //         googleAnalytics.logPageView();
    //         let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
    //         let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
    //         let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
    //         this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
    //     } else {
    //         console.log("GA disabled...");
    //     }
    // } catch(error) {
    //     console.error("Error occured while logging to GA, error = ", error);
    // }
  };
  async componentDidMount() {
    //console.log("Box ",localStorage.getItem('token'));
    // this.logPageView(); // Log page view to GA
    // this.renderMapKey()
    var params = {};
    params.startDate = this.state.startDate;
    params.endDate = this.state.endDate;

    await redirectURL
      .post("master/getAllPlantCodeInfo")
      .then((response) => {
        if (response.data.length > 0) {
          this.setState({
            plantDetails: response.data,
          });
          var plants = response.data.map(function (e) {
            if (e.plant_name != "") {
              return { label: e.plant_name, value: e.plant_name };
            }
          });
          // this.setState({
          //     plants_list : plants
          // })
          this.setState((prevState) => ({
            plants_list: [...prevState.plants_list, ...plants],
          }));
        }
      })
      .catch((e) => {
        console.log(e, "error mssge");
      });

    // console.log(params,"default loading params")
    await redirectURL
      .post("master/getPlantBasedRecords", params)
      .then((resp) => {
        if (resp.data.length > 0) {
          let defaultArr = resp.data;
          let plantLookup = {};
          this.state.plantDetails.forEach((plant) => {
            plantLookup[plant.plant_code] = plant.plant_name;
          });
          defaultArr.forEach((obj) => {
            obj.plant_name = plantLookup[obj.plant_code] || "Unknown Plant";
          });

          // Now resp array contains plant_name in each object based on plant_code match with plantDetails

          this.setState({
            defaultRecords: resp.data,
          });
        }
      })
      .catch((e) => {
        console.log(e, "error mssge");
      });
  }
  selectOptionsItems() {
    let items = [];
    //console.log(this.state.trucks);
    this.state.trucks.forEach((item) => {
      if (item != "" && item != null) {
        items.push(
          <option key={item.truck_no} value={item.truck_no}>
            {item.truck_no}
          </option>
        );
      }
    });

    return items;
  }

  onCloseUploadDiv = () => {
    // console.log("function call");
    this.setState({
      uploadDivWidth: "0%",
      sliderTranslate: "",
      sideBar: "",
      tabsliderTranslate: "",
      showDiv: "show-n",
      uploadFile: "",
      file: "",
      csvmessage: "",
      csverrmessage: "",
      overly: "show-n",
    });
  };
  onCloseRouteDiv = () => {
    this.setState({
      uploadDivWidth: "0%",
      sliderRouteTranslate: "",
      showDiv: "show-n",
      sliderCommentTranslate: "",
      uploadFile: "",
      file: "",
      csvmessage: "",
      csverrmessage: "",
      overly: "show-n",
      overlyRing: "show-n",
    });
  };

  onShowRouteDiv = async (params) => {
    let logParams = {
      // "location_code" : location.value,
      // "location_name" : location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "unScheduledStoppages",
      activity: "clicked on enroute stoppege icon  in the aggrid",
      event: "Aggrid opened below the row",
      // "data_type" : this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    console.log("Consign Overspeed ", params);

    if (params) {
      // let eventLabel = googleAnalytics.page.action.enrouteStoppages;
      // let eventOptions = {
      // 	"category": this.state.pagetitle,
      // 	"action": this.state.eventAction,
      // 	"label": eventLabel,
      // }
      // googleAnalytics.logEvent(eventOptions);
      var overspeedData = [];

      //console.log("Step 3 ",overspeedData)

      // console.log(params,'445')
      this.setState({
        sliderRouteTranslate: "slider-translate-60p",

        mapinfo: params,

        consignment_code: "Truck NO : " + params.data.truck_no,
        maptruckno: params.data.truck_no,
        truckNo: params.data.truck_no,
        routeTruck: {
          truck_no: params.data.truck_no,
          stoppage_start_time: params.data.break_start,
          stoppage_end_time: params.data.break_end,
          address: params.data.break_address,
        },
        loadshow: "show-n",
        sidebarSubHeader: "Truck Route Details",
        overly: "show-m",
        rownode: params.data,
        laitude: params.data.lat,
        longitude: params.data.lng,
      });
      // console.log("s5 ")
      // this.renderMap();
      // if (params.column.colDef.field == 'showtruck') {

      // 	params.node.setExpanded(!params.node.expanded);
      // }
      // else {

      // 	params.node.setExpanded(false);
      // }
      //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
    } else {
      this.setState({
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: [
              { headerName: "Truck No", field: "truck_no" },
              { headerName: "Speed (km/h)", field: "speed" },
              { headerName: "Reported At", field: "first_instance" },
            ],
          },
          getDetailRowData: function (params) {
            //console.log("Child ", params);
            params.successCallback([]);
          },
        },
      });
    }
  };
  handlerStartDateTime = (event) => {
    var d = new Date(event.target.value);
    // var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    this.setState({
      startDate: getYYYYMMDDHHMMSS(d),
    });
    //let value = event.target.value;
    //this.setState({'startDate':value});
  };

  handlerEndDateTime = (event) => {
    var d = new Date(event.target.value);
    // console.log(event.target.value,'l',d,'l',getYYYYMMDDHHMMSS(d))
    // var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
    this.setState({
      endDate: getYYYYMMDDHHMMSS(d),
    });

    //let value = event.target.value;
    //this.setState({'startDate':value});
  };
  // initalList =(event)=>{
  //     $(".trucks-dropdown").removeClass("show-n");
  //     var dataset = this.state.plants_list;
  //     dataset = dataset.slice(0,100);
  //     this.setState({
  //         filteredData : dataset,
  //         showTrucksList : "show-m",
  //     })
  // }

  onChangePlant = (plant_name) => {
    this.setState(
      { plant_name }
      // () => console.log(`Name  Option selected:`, plant_name)
    );
  };

  onClickTruck(event) {
    $("#inputTruck").val($(this).text());
  }
  formHandler = async (event) => {
    this.setState({
      loadshow: "show-m",
    });
    event.preventDefault();
    // if (googleAnalytics.page.enableGA) {
    //     let pageTitle = this.state.pageTitle;
    //     let eventOptions = {
    //         "category": pageTitle,
    //         "action": this.state.eventFormAction,
    //         "label": googleAnalytics.page.action.formSubmittedGenerate,
    //     }
    //     googleAnalytics.logEvent(eventOptions);
    // }
    //var formdata = new FormData(event.target);
    // console.log($("#inputTruck").val())
    var { startDate, endDate } = this.state;
    var check = startDate < endDate;
    //    console.log(startDate,endDate,'dakjkj')
    var dataset = this.state.plants_list;
    var truckNo = $("#inputTruck").val();
    if (this.state.plant_name === "") {
      this.setState({
        show: true,
        basicTitle: "Please Enter Plant Name",
        basicType: "danger",
        loadshow: "show-n",
      });
    } else if (!check) {
      this.setState({
        show: true,
        basicTitle: "Start Date Should be Less than End Date",
        basicType: "danger",
        loadshow: "show-n",
      });
    } else {
      let formdata = {};
      if (!this.state.plant_name.value) {
        formdata = {
          startDate: getHyphenYYYYMMDDHHMMSS(this.state.startDate),
          endDate: getHyphenYYYYMMDDHHMMSS(this.state.endDate),
        };
      } else {
        let plantCode = this.state.plantDetails.find((e) => {
          if (e.plant_name === this.state.plant_name.value) {
            return e;
          }
        });
        plantCode = plantCode.plant_code ? plantCode.plant_code : plantCode;
        formdata = {
          plant_code: plantCode,
          startDate: getHyphenYYYYMMDDHHMMSS(this.state.startDate),
          endDate: getHyphenYYYYMMDDHHMMSS(this.state.endDate),
        };
      }

      await redirectURL
        .post("master/getPlantBasedRecords", formdata)
        .then(async (result) => {
          var plantWiseRecords = result.data;
          if (result.data.status === "fail") {
            this.setState({
              show: true,
              basicTitle: "No Data Found",
              basicType: "danger",
              loadshow: "show-n",
              defaultRecords: [],
            });
          } else {
            let plantLookup = {};
            this.state.plantDetails.forEach((plant) => {
              plantLookup[plant.plant_code] = plant.plant_name;
            });
            plantWiseRecords.forEach((obj) => {
              obj.plant_name = plantLookup[obj.plant_code] || "Unknown Plant";
            });

            this.setState({
              defaultRecords: plantWiseRecords,
              loadshow: "show-n",
              overly: "show-n",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  onClickShowGrid() {
    this.setState({
      gridshow: "show-m",
      mapshow: "show-n",
      mapinfoclick: "",
      actgridbtn: "btn-success",
      actmapbtn: "btn-default",
    });
  }

  onClickShowMap() {
    console.log("hit");
    this.setState({
      gridshow: "show-n",
      mapshow: "show-m",
      mapinfoclick: this.state.mapinfo,
      actgridbtn: "btn-default",
      actmapbtn: "btn-success",
    });
  }
  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      showTrucksList: "show-n",
      sliderRouteTranslate: "",
    });
  }
  hideList() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      showTrucksList: "show-n",
    });
  }
  closeAlert = () => {
    this.setState({
      show: false,
    });
  };
  setTruckno = (event) => {
    this.setState({
      selected_truck_no: event.target.text,
    });
    // console.log(event.target.text)
  };

  checkHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };
  // renderMapKey = () => {
  // 	loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDOkvMDXyKxdnXp4K-QMNIdjw_dgpzebo8&libraries=places,drawing");
  // }
  // renderMap = () => {
  // 	window.initMap = this.initMap
  // }
  // initMap = () => {
  //     //console.log("arr ",arr)
  //     var currentwindow;
  // 	var dealer='';
  // 	var tolls = this.state.tolls;

  // 	var arr=[];
  //     var jsondata = this.state.mapinfo;
  //     var locations = jsondata.breaks;
  //     var arr = jsondata.coords;
  // 	if(arr.length > 0 && typeof arr.length != undefined)
  // 	{
  //         var lt=arr[0].lat;
  //         var ln=arr[0].lng;
  // 	}
  // 	else{
  //         var lt=21.141058047268885;
  //         var ln=72.65782317936426;

  // 	}
  //     var routeinfo ;
  //     try{
  // 		var mapOptions = {
  // 			zoom: 13,
  // 			zoomControl: true,
  // 			mapTypeControl: true,
  // 			scaleControl: true,
  // 			streetViewControl: true,
  // 			rotateControl: true,
  // 			fullscreenControl: true,
  // 			labels:true,
  // 			mapTypeControlOptions: {
  // 				mapTypeIds: ['satellite', 'roadmap'],
  // 			},
  // 			center: new window.google.maps.LatLng(lt,ln),
  // 			mapTypeId: window.google.maps.MapTypeId.ROADMAP
  // 		};

  // 	}
  // 	catch(e){
  // 		var mapOptions = {
  // 			zoom: 13,
  // 			zoomControl: true,
  // 			mapTypeControl: true,
  // 			scaleControl: true,
  // 			streetViewControl: true,
  // 			rotateControl: true,
  // 			fullscreenControl: true,
  // 			labels:true,
  // 			mapTypeControlOptions: {
  // 				mapTypeIds: ['satellite', 'roadmap'],
  // 			},
  // 			center: new window.google.maps.LatLng(28.4519751,77.0310713),
  // 			mapTypeId: window.google.maps.MapTypeId.ROADMAP
  // 		};

  // 	}
  //     try{
  // 		map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
  // 	}catch(e){}

  // 	var bounds = new window.google.maps.LatLngBounds();
  // 	bounds.extend(new window.google.maps.LatLng(lt,ln));
  // 		// Create our info window content
  // 	var currentinfowindow = null;
  // 	var line=new window.google.maps.Polyline(
  // 	{
  // 		map:map,
  // 		strokeColor: '#157254',
  // 		strokeOpacity: 1.0,
  // 		strokeWeight: 2.5,
  // 		// icons: [{
  // 		// 		icon: {
  // 		// 				path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  // 		// 				strokeColor:'#ff8c52',
  // 		// 				fillColor:'#ff8c52',
  // 		// 				fillOpacity:1,
  // 		// 				strokeWeight: 2
  // 		// 		},
  // 		// 		repeat:'100px',
  // 		// 		path:[]
  // 		// 	}]
  //         });
  //     if(arr.length > 0)
  // 	{
  //         var allpoints = [];

  // 		var arr1=[];
  // 		//console.log("Total ",ratio);
  // 		if(this.state.timelinesmarkers.length > 0)
  // 		{
  // 			arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
  // 				return (index % ratio == 0);
  // 			} );

  // 			//var arr1 = this.state.timelinesmarkers;
  //         }

  //         var arr2=[];
  //         var darr=[];
  //         if(arr.length > 0)
  // 		{
  // 			darr = arr.filter(function (value, index, arr) {
  // 				//console.log("Vale ",value)
  // 				return (value.dist_from_prev_point > 0);
  // 			} );
  // 			if(darr.length < 2000)
  // 			{
  // 				var ratio = 1;
  // 			}
  // 			else{
  // 				var ratio = 20;
  // 			}
  // 			//console.log("darr.length ", darr.length)
  // 			arr2 = darr.filter(function (value, index, darr) {
  // 				return (index % ratio == 0);
  // 			} );

  // 			//var arr1 = this.state.timelinesmarkers;
  //         }
  //         // view markers code
  //         if(arr2.length > 0)
  //         {

  //             viewMarkersArr = arr;
  //                 // //console.log("allpoints ", allpoints)

  //                 for(var a=0;a<arr2.length;a++)
  //                 {
  //                     //console.log(arr2[a],"arr2[a]");
  //                     var marker = new window.google.maps.Marker({
  //                         position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
  //                         icon: {
  //                                 path: window.google.maps.SymbolPath.CIRCLE,
  //                                 strokeColor:'#157254',
  //                                 fillColor:'#157254',
  //                                 fillOpacity:1,
  //                                 strokeWeight: 5,
  //                                 scale:1,
  //                             },
  //                         map: map,
  //                         content:arr2[a]
  //                     });
  //                     marker.setVisible(false);
  //                     // 	//console.log("Arr ",arr1[a])
  //                     window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
  //                         return function() {
  //                             //console.log("Arr ",marker)
  //                             var contentarr = []
  //                         //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
  //                         var header = "Current Status"
  //                         contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
  //                         contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})

  //                         var contentString = infoBox("", header, contentarr,'')

  //                         infowindow.setContent(contentString);
  //                         currentwindow = infowindow;
  //                         infowindow.open(map, marker);
  //                         //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
  //                         }
  //                     })(marker));
  //                     window.google.maps.event.addListener(marker, 'mouseout', function() {
  //                         infowindow.close();
  //                     });

  //                 }
  //         }
  //         var routeinfo = jsondata.route_details;
  //         var icon_cnt = 100
  //         for(var i=0;i< arr2.length-1;i++)
  //         {
  //             var locationLatLng = [];
  //             locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
  //             locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
  //             if(icon_cnt % 10 == 0)
  //             {
  //                 var lineicon = [{
  //                             // icon: {
  //                             //         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  //                             //         strokeColor:'#FF0000',
  //                             //         fillColor:'#FF0000',
  //                             //         fillOpacity:1,
  //                             //         strokeWeight: 2
  //                             // },
  //                             repeat:'100px',
  //                             path:[]
  //                         }];
  //             }else{lineicon=[]}
  //             icon_cnt = icon_cnt + 1
  //             var consignments_missing_route_line = new window.google.maps.Polyline({
  //                 map:map,
  //                 path : locationLatLng,
  //                 strokeColor: '#157254',
  //                 strokeOpacity: 1.0,
  //                 strokeWeight: 2.5,
  //                 icons: lineicon
  //             });
  //             // console.log(arr2[i].msgtype)
  //             if(arr2[i].msgtype == "G")
  //             {

  //                 consignments_missing_route_line.setOptions({
  //                     strokeColor : "#452a68",
  //                     strokeWeight: 5.5,
  //                 })
  //             }
  //             bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
  //         }

  //             // for (let i = 0; i < arr2.length; i++) {
  //             //     //if(arr2[i].speed > 0)
  //             //     //{
  //             //         var linecolr = "#157254";
  //             //         if(arr2[i].set  == 2)
  //             //         {
  //             //             linecolr = "#0000ff";
  //             //         }
  //             //         if(arr2[i].set  == 3)
  //             //         {
  //             //             linecolr = "#980000"
  //             //         }
  //             //         line.setOptions({strokeColor: linecolr});
  //             //     var path=line.getPath().getArray();

  //             //     let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
  //             //     path.push(latLng);
  //             //     line.setPath(path);

  //             //     //Change line color based on map type
  //             //     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
  //             //         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
  //             //         {
  //             //             var line=new window.google.maps.Polyline(
  //             //             {
  //             //                 map:map,
  //             //                 strokeColor: '#FFFFFF',
  //             //                 strokeOpacity: 1.0,
  //             //                 strokeWeight: 2.5,
  //             //                 icons: [{
  //             //                         icon: {
  //             //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  //             //                                 strokeColor:'#ff8c52',
  //             //                                 fillColor:'#ff8c52',
  //             //                                 fillOpacity:1,
  //             //                                 strokeWeight: 2
  //             //                                 },
  //             //                         repeat:'100px',
  //             //                         path:[]
  //             //                     }]
  //             //                 });
  //             //         }
  //             //         else
  //             //         {
  //             //             var line=new window.google.maps.Polyline(
  //             //             {
  //             //                 map:map,
  //             //                 strokeColor: '#157254',
  //             //                 strokeOpacity: 1.0,
  //             //                 strokeWeight: 2.5,
  //             //                 icons: [{
  //             //                         icon: {
  //             //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  //             //                                 strokeColor:'#ff8c52',
  //             //                                 fillColor:'#ff8c52',
  //             //                                 fillOpacity:1,
  //             //                                 strokeWeight: 2
  //             //                                 },
  //             //                         repeat:'100px',
  //             //                         path:[]
  //             //                     }]
  //             //                 });

  //             //         }

  //             //         for (i = 0; i < arr2.length; i++) {
  //             //                 var path=line.getPath().getArray();
  //             //                 let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
  //             //                 path.push(latLng);
  //             //                 line.setPath(path);
  //             //                         //map.setCenter(latLng);
  //             //             }

  //             //         var x = map.getZoom();
  //             //         var c = map.getCenter();
  //             //         window.google.maps.event.trigger(map, 'resize');
  //             //         map.setZoom(x);
  //             //         map.setCenter(c);
  //             //     } );
  //             //     bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
  //             //     //}
  //             // }

  // 			var infowindow = new window.google.maps.InfoWindow();
  // 				var marker, l;

  // 				for (l = 0; l < locations.length; l++) {
  // 				marker = new window.google.maps.Marker({
  // 				position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
  // 				icon:require(''),
  // 				map: map,
  // 				});

  // 				window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
  // 					return function() {
  // 						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

  // 					var contentarr = []
  // 					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
  // 					var header = "Break";
  // 					try{
  // 						if(typeof this.props.mapFor != 'undefined')
  // 						{
  //                             var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
  //                             contentarr.push({"key":"Break Start", "value":locations[l].break_start})
  //                             contentarr.push({"key":"Break End", "value":locations[l].break_end})
  //                             contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
  // 						}
  // 						else
  // 						{
  //                             contentarr.push({"key":"Break Start", "value":locations[l].break_start})
  //                             contentarr.push({"key":"Break End", "value":locations[l].break_end})
  //                             contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
  //                         }
  // 					}
  // 					catch(e){
  //                         contentarr.push({"key":"Break Start", "value":locations[l].break_start})
  //                         contentarr.push({"key":"Break End", "value":locations[l].break_end})
  //                         contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
  // 					}

  // 					var contentString = infoBox(marker.icon, header, contentarr,'')

  // 					infowindow.setContent(contentString);
  // 					currentwindow = infowindow;
  // 					infowindow.open(map, marker);
  // 					//console.log(marker.position.toJSON());
  // 					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
  // 					}
  // 				})(marker, l));
  // 				}
  // 				marker = new window.google.maps.Marker({
  // 					position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
  // 					icon:require(''),
  // 					map: map,
  // 				});
  // 			window.google.maps.event.addListener(marker, 'click', (function(marker) {
  // 					return function() {
  // 						//	console.log(clusters[0])

  // 						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
  // 					var contentarr = []
  // 					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
  // 					var header = "Starting Point"
  // 					contentarr.push({"key":"Started at", "value":routeinfo.start_time})

  // 					var contentString = infoBox(marker.icon, header, contentarr,'')

  // 					infowindow.setContent(contentString);
  // 					currentwindow = infowindow;
  // 					infowindow.open(map, marker);
  // 					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
  // 					}
  // 				})(marker));
  // 			marker = new window.google.maps.Marker({
  // 				position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
  // 				icon:require(''),
  // 				map: map,
  // 			});
  // 			window.google.maps.event.addListener(marker, 'click', (function(marker) {
  // 					return function() {
  // 						//	console.log(clusters[0])

  // 					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
  // 					var contentarr = []
  // 					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
  // 					var header = "End Point"
  // 					contentarr.push({"key":"End at", "value":routeinfo.end_time})

  // 					var contentString = infoBox(marker.icon, header, contentarr,'')

  // 					infowindow.setContent(contentString);
  // 					infowindow.setContent(contentString);
  // 					currentwindow = infowindow;
  // 					infowindow.open(map, marker);
  // 					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
  // 					}
  // 				})(marker));

  //         }
  //         try{
  //             if(tolls.length > 0)
  //             {
  //                 var infowindow = new window.google.maps.InfoWindow();
  //                 var l;
  //                 tolls.map(function(e,index){
  //                     var tollMarker = new window.google.maps.Marker({
  //                         position: new window.google.maps.LatLng(e.lat, e.lon),
  //                         icon:require(''),
  //                         map: map,
  //                     });
  //                     window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
  //                         return function()
  //                         {
  //                             var contentarr = []
  //                             var header = "Toll - "+e.name
  //                             contentarr.push({"key":"Address", "value":e.location})
  //                             var contentString = infoBox(tollMarker.icon, header, contentarr)
  //                             infowindow.setContent(contentString);
  //                             var currentwindow = infowindow;
  //                             infowindow.open(map, tollMarker);
  //                         }
  //                     })(tollMarker, index));
  //                     window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
  //                         infowindow.close();
  //                         });
  //                 })

  //             }
  //         }catch(e){}

  //         map.fitBounds(bounds)

  // }

  // onClickShowTruckLocation(e){
  //     // console.log("CLiclabel ", e)
  //     var lat = parseFloat(e.data.lat);
  // 	var lng =parseFloat(e.data.lng);
  // 	var data =e.data;
  // 	map.setCenter(new window.google.maps.LatLng(lat,lng));
  // 	map.setZoom(22);
  // 	var markerLatlng = new window.google.maps.LatLng(lat, lng);
  // 	//var image =  require('../../assets/icons/truckblue.png');
  // 	var image =  require('../../assets/icons/gmarker.png');

  // 	var marker = new window.google.maps.Marker({
  // 		position: markerLatlng,
  // 		map: map,
  // 		title:data.truck_no,
  // 		icon:image
  // 	});

  // 	var contentarr = []
  // 	var header = data.truck_no
  // 	//contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
  // 	contentarr.push({"key":"City/Area", "value":data.area})
  // 	contentarr.push({"key":"State", "value":data.state})

  // 	var contentString = infoBox(marker.icon, header, contentarr,'')

  // 	var infowindow = new window.google.maps.InfoWindow({
  // 		content: contentString
  // 	});

  // 	marker.setAnimation(window.google.maps.Animation.DROP)
  // 	marker.addListener('click', function() {
  // 		infowindow.open(map, marker);
  // 	});

  // 	marker.setMap(map);

  // }
  showmarkers(event) {
    // console.log(event.target.value);
    // console.log("viewMarkersArr ", viewMarkersArr)
    // if(event.target.value == 0)
    // {
    //     // viewMarkersArr.map(function(e){
    // 	// 	e.setVisible(true);
    //     // });
    //     this.setState({
    //         showmarkers : 1
    //     })
    // }
    // else
    // {
    // 	// viewMarkersArr.map(function(e){
    // 	// 	e.setVisible(false);
    //     // });
    //     this.setState({
    //         showmarkers : 0
    //     })
    // }

    var infowindow = new window.google.maps.InfoWindow();
    var markersArray = viewMarkersArr;
    if (event.target.value == 0) {
      if (markersArray.length > 0) {
        var stepby = Math.round(markersArray.length * 0.007);
        // var counter = ( stepby > 0)?stepby:1;
        // var incrementby = counter
        var counter = 1;
        var incrementby = 1;
        // console.log(markersArray.length,counter);
        while (counter < markersArray.length) {
          var marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              markersArray[counter].lat,
              markersArray[counter].lng
            ),
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              strokeColor: "#157254",
              fillColor: "#157254",
              fillOpacity: 1,
              strokeWeight: 5,
              scale: 1,
            },
            map: map,
            content: markersArray[counter],
          });
          // marker.setVisible(false);
          window.google.maps.event.addListener(
            marker,
            "mouseover",
            (function (marker) {
              return function () {
                //console.log("Arr ",marker)
                var contentarr = [];
                //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                var header = "Current Status";
                contentarr.push({
                  key: "Time ",
                  value: getDDMMYYYYHHMMSS(marker.content.stime),
                });
                contentarr.push({
                  key: "Speed ",
                  value: marker.content.speed + " KMPH",
                });

                var contentString = infoBox("", header, contentarr, "");

                infowindow.setContent(contentString);
                infowindow.open(map, marker);
                //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
              };
            })(marker)
          );
          window.google.maps.event.addListener(marker, "mouseout", function () {
            infowindow.close();
          });

          // viewMarkersArr.push(marker);
          counter = counter + incrementby;
          // console.log("counter",counter)
        }
      }
    } else {
      viewMarkersArr.map(function (e) {
        e.setVisible(false);
      });
      this.setState({
        showmarkers: 0,
      });
    }
  }

  getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  onChangeStartDate = (e) => {
    this.setState({
      startDate: e.target.value,
    });
  };
  onChangeEndDate = (e) => {
    this.setState({
      endDate: e.target.value,
    });
  };
  render() {
    let order_cnt = [];

    const dStyles = {
      width: "100%",
      height: "100%",
    };
    const modalStyles = {
      width: "500px !important",
    };
    const { open, plant_name, plants_list } = this.state;
    const columnwithDefs = [
      {
        headerName: "",
        field: "showtruck",
        colId: "showtruck",
        width: 40,
        pinned: "left",
        pivot: false,
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "showTruckLocationComp",
          };
          return rendComponent;
        },
        filter: true,
        resizable: true,
      },
      {
        headerName: "Plant name",
        field: "plant_name",
        width: 100,
        filter: true,
        resizable: true,
      },
      // {
      //     headerName: "Plant Code",
      //     field: "plant_code",
      //     width: 120,
      //     filter: true,
      //     resizable: true,
      // },
      {
        headerName: "Truck No",
        field: "truck_no",
        width: 120,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Shipment Id",
        field: "shipment_id",
        width: 140,
        filter: true,
        resizable: true,
      },
      {
        headerName: "City",
        field: "city",
        width: 140,
        filter: true,
        resizable: true,
      },
      {
        headerName: "State",
        field: "break_state",
        width: 140,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Stoppage Address",
        field: "break_address",
        width: 340,
        filter: true,
        resizable: true,
      },
      {
        headerName: "Stoppage Duration",
        field: "break_time_seconds",
        width: 340,
        filter: true,
        resizable: true,
      },
    ];

    // var listData = []
    // this.state.filteredData.map(function(e,index){
    // listData.push(<li className="dropdown-truck-no" key={index}>{e}</li>)
    // })
    return (
      <div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        {this.state.alert}
        {/*Container-fluid starts*/}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="fbold  d-flex justify-content-between">
                <span>Unscheduled Stoppages</span>
              </h5>
            </div>
          </div>
          <div className="row col-xl-12 col-lg-12">
            {/* <div className={"trucks-dropdown "+this.state.showTrucksList}>
                            <ul>
                                {listData}
                            </ul>
                         </div> */}
            <form
              className="row theme-form col-md-12 "
              onSubmit={this.formHandler}
            >
              <div className="col-xl-2 col-lg-3 form-group">
                <label className="c-lbl fbold">Pickup Location</label>
                <Select
                  placeholder="Select Plant"
                  closeMenuOnSelect={true}
                  onChange={this.onChangePlant}
                  className={"col-xl-12 col-lg-12 border-radius-0"}
                  style={{ borderRadius: "0px" }}
                  name="plant_name"
                  value={plant_name}
                  options={plants_list}
                />
              </div>
              <div className="col-md-2 form-group">
                <label className="c-lbl fbold">From Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="startData"
                  id="startData"
                  autoComplete="off"
                  onKeyDown={(e) => e.preventDefault()}
                  style={{ borderRadius: "0px" }}
                  max={this.getCurrentDateTime()}
                  onChange={this.onChangeStartDate}
                  value={this.state.startDate}
                  readonly
                />
              </div>
              <div className="col-md-2 form-group">
                <label className="c-lbl fbold">To Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="endDate"
                  id="endDate"
                  autoComplete="off"
                  style={{ borderRadius: "0px" }}
                  onKeyDown={(e) => e.preventDefault()}
                  max={this.getCurrentDateTime()}
                  onChange={this.onChangeEndDate}
                  value={this.state.endDate}
                  readonly
                />
              </div>
              {/* <div className="col-xl-2 col-lg-2 form-group">
                                <label className="c-lbl fbold">Data Interval (Mins)</label>
	                    		<input type="number" name="frequency" id="frequency" value={this.state.frequency} placeholder="Data Interval (Mins)" className="validateNumber form-control" onChange={this.checkHandler.bind(this)} />
	                    	</div> */}
              <div className="col-xl-2 col-lg-2  form-group">
                <label></label>
                <button
                  type="submit"
                  className="btn btn-success"
                  style={{ marginTop: "3em" }}
                >
                  Generate
                </button>
              </div>
            </form>
          </div>
          {this.state.defaultRecords != null ? (
            <div className="row col-xl-12 col-lg-12">
              <div
                id="myGrid"
                style={{ height: "600px", width: "100%", marginTop: "10px" }}
                className={"ag-theme-balham " + this.state.gridshow}
              >
                <AgGridReact
                  modules={this.state.modules}
                  columnDefs={columnwithDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.defaultRecords}
                  enableCharts={true}
                  enableRangeSelection={true}
                  onGridReady={this.onGridReady}
                  onGridState={this.onGridState}
                  statusBar={this.state.statusBar}
                  sideBar={this.state.sideBar}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  frameworkComponents={this.state.frameworkComponents}
                  enableCellChangeFlash={true}
                  suppressCellFlash={true}
                  gridOptions={{
                    context: { componentParent: this },
                    getContextMenuItems: (params) => {
                      return [
                        "copy", // Default AG Grid options like copy
                        "copyWithHeaders",
                        "paste",
                        {
                          name: "Export to CSV", // Custom option for exporting to CSV
                          action: () => {
                            params.api.exportDataAsCsv();
                          },
                        },
                        {
                          name: "Export to Excel", // Custom option for exporting to Excel
                          action: () => {
                            params.api.exportDataAsExcel();
                          },
                        },
                      ];
                    },
                  }}

                  // pinnedBottomRowData={this.state.tottalDistance}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.sliderRouteTranslate != "" ? (
            <div
              className={"slide-r " + this.state.sliderRouteTranslate}
              style={{ overflow: "auto" }}
            >
              <div className="slide-r-title">
                <h4>
                  {this.state.consignment_code}
                  <span
                    className="float-right closebtn"
                    style={{ marginRight: "25px", cursor: "pointer" }}
                    onClick={this.onCloseRouteDiv.bind(this)}
                  >
                    X
                  </span>
                </h4>
              </div>
              <div className="slide-r-body" style={{ position: "relative" }}>
                <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                  <DrawMap
                    mapFor={"unscheduledStoppageTrucks"}
                    consignercords={this.state.mapinfo}
                    rownode={this.state.rownode}
                    truckno={this.state.truckNo}
                    mapinfo={this.state.mapinfo}
                  />
                  <div className="col-xl-12 col-lg-12 n-p-0">
                    <div className="crm-numbers pb-0">
                      <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                      <div className="col-xl-12 col-lg-12 row">
                        <div className="col route-block">
                          <label className="sidebar-label">Truck No</label>
                          <div>{this.state.routeTruck.truck_no}</div>
                        </div>
                        <div className="col route-block">
                          <label className="sidebar-label">
                            Stoppage Start Time
                          </label>
                          <div>
                            {this.state.routeTruck.stoppage_start_time != ""
                              ? getDDMMYYYYHHMMSS(
                                  this.state.routeTruck.stoppage_start_time
                                )
                              : "NA"}
                          </div>
                        </div>
                        <div className="col route-block">
                          <label className="sidebar-label">
                            Stoppage End Time
                          </label>
                          <div>
                            {this.state.routeTruck.stoppage_end_time != ""
                              ? getDDMMYYYYHHMMSS(
                                  this.state.routeTruck.stoppage_end_time
                                )
                              : "NA"}
                          </div>
                        </div>

                        <div className="col route-block">
                          <label className="sidebar-label">Break Address</label>
                          <div>
                            {this.state.routeTruck
                              ? this.state.routeTruck.address
                                ? this.state.routeTruck.address
                                : ""
                              : ""}
                          </div>
                        </div>

                        {/* <div className="col route-block">
												<label className="sidebar-label">Break</label>
												<div>{this.state.mapinfo ? this.state.mapinfo.break_start? this.state.mapinfo.break_start :"" : "0"}</div>
											</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={"dataLoadpage " + this.state.loadshow}></div>
          <div className={"dataLoadpageimg " + this.state.loadshow}>
            <div class="loader-box">
              <div class="loader-box">
                <div class="rotate dashed colored"></div>
              </div>
            </div>
          </div>
        </div>
        {/*Container-fluid Ends*/}
        <div className={"loader " + this.state.loadshow}></div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.onClickHideAll.bind(this)}
        ></div>
      </div>
    );
  }
}

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

function dateComparator(date1, date2) {
  // console.log("dateComparator");
  // console.log(date1.length);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
}
function monthToComparableNumber(date) {
  // console.log(date,date.length);
  if (date === undefined || date === null || date.length !== 16) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  // console.log(yearNumber,monthNumber,dayNumber);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}

function getMonthNumber(monthName) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var monthNum = months.indexOf(monthName) + 1;
  if (monthNum < 10) {
    monthNum = "0" + monthNum.toString();
  }
  return monthNum;
}
$(document).ready(function () {
  $(document).on("click", ".dropdown-truck-no", function () {
    var vals = $(this).text();
    $("#inputTruck").val(vals);
    $(".trucks-dropdown").removeClass("show-m");
    $(".trucks-dropdown").addClass("show-n");
  });

  $("body").on("click", function (e) {
    var container = $("#inputTruck");
    if (!container.is(e.target)) {
      try {
        if (
          $(".trucks-dropdown").attr("class").includes("show-m") ||
          $(".trucks-dropdown").attr("class") == "trucks-dropdown"
        ) {
          $(".trucks-dropdown").removeClass("show-m");
          $(".trucks-dropdown").addClass("show-n");
        }
      } catch (e) {}
    }
  });
});

function loadScript(url) {
  var index = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

function timeConvert(n) {
  var num = n;
  var hours = num / 3600;
  var rhours = parseInt(hours);
  var minutes = (num - rhours * 3600) / 60;
  var rminutes = Math.round(minutes);
  return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function ConvertMinutes(num) {
  var d = Math.floor(num / 1440); // 60*24
  var h = Math.floor((num - d * 1440) / 60);
  var m = Math.round(num % 60);
  return d + " Day(s) " + h + " Hrs " + m + " mins";
}

function ConvertSeconds(totalSeconds) {
  // var days = Math.floor(num/86400);
  // var hours = Math.floor(num/3600)
  // var minutes = Math.floor(num/60);
  var minutes = Math.round((totalSeconds % 3600) / 60);
  var hours = Math.round((totalSeconds % 86400) / 3600);
  var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
  // let d = (new Date(t0)) - (new Date(t1));
  // let weekdays     = Math.floor(d/1000/60/60/24/7);
  // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
  // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
  // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
  return days + " Day(s) " + hours + " Hrs " + minutes + " mins";
}

function convertMeters(metersValue) {
  return (metersValue / 1000).toFixed(2);
}

$(document).ready(function () {
  $(document).on("keyup", ".validateNumber", function () {
    // alert("click");
    if (isNaN($(this).val()) == true) {
      $(this).val($(this).val().slice(0, -1));
    }
  });
});

function arrayMax(arr) {
  return arr.reduce(function (p, v) {
    return p > v ? p : v;
  });
}

function secondsToString(seconds) {
  var numdays = Math.floor(seconds / 86400);
  var numhours = Math.floor((seconds % 86400) / 3600);
  var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
  var numseconds = ((seconds % 86400) % 3600) % 60;
  return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
  var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
  var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay;
}
