import React from "react";
import PWConfigL1 from "./plantWConfigL1";

export default function ConfigsInRunNew(props) {
  var { savePlantConfigs } = props;
  var { data } = props;

  var uppKeys = [
    "Prioritize Local Loads",
    "City Based Entry Restriction",
    // "Follow Telescopic Routes",
    "Consider Vehicle Break Times (Night Driving)",
    "Consider Dropping Distant Smaller Loads",
    "Consider Holiday Restrictions",
    "Consider Realtime Vehicle Available Times",
    "Plan Spot Vehicles",
    "Consider Transporter SOB",
  ];

  var lower1 = [
    "Use Dynamic Planning Window",
    "Number of Days to Use Planning Window",
    "Optimization Type*",
  ];

  var lower2 = [
    "Use Volume for Vehicle Fill Rate (VFR)",
    "Use Weight for Vehicle Fill Rate (VFR)",
    "Min VFR Per Vehicle Type",
    "Min VFR Per Spot Vehicle",
    "MOQ for Planning",
  ];
  var lower3 = [
    "Number of Loading Docks",
    // "Max No of Customers Per Vehicle",
    "Max Local No of Customers Per Vehicle",
    "Max Upcountry No of Customers Per Vehicle",
    "Prioritize Same Dealers First in a Vehicle",
    "Prioritize Multi City Dealers First in a Vehicle",
  ];

  var uppOpt = {},
    lowOpt1 = {},
    lowOpt2 = {},
    lowOpt3 = {};
  var configs = {};
  if (Object.keys(data).length) {
    configs = data;
  }

  var userType = localStorage.getItem("user_type") !== "admin";
  if (Object.keys(configs).length > 0) {
    uppKeys = uppKeys.filter((key) => configs.hasOwnProperty(key));
    uppKeys.map((item) => {
      uppOpt[item] = configs[item];
      if (userType) {
        uppOpt[item].disabled = true;
      }
      return "";
    });
    lower1 = lower1.filter((key) => configs.hasOwnProperty(key));
    lower1.map((item) => {
      lowOpt1[item] = configs[item];
      if (userType) {
        lowOpt1[item].disabled = true;
      }
      return "";
    });
    lower2 = lower2.filter((key) => configs.hasOwnProperty(key));
    lower2.map((item) => {
      lowOpt2[item] = configs[item];
      // if(configs[item]===undefined){
      //   console.log(item)
      // }
      if (userType) {
        lowOpt2[item].disabled = true;
      }
      return "";
    });
    lower3 = lower3.filter((key) => configs.hasOwnProperty(key));
    lower3.map((item) => {
      if (configs[item].type === undefined) {
      }
      lowOpt3[item] = configs[item];
      if (userType) {
        lowOpt3[item].disabled = true;
      }
      return "";
    });
  }

  return (
    <>
      <div
        className="col-md-12 "
        style={{
          height: "100%",
          width: "90%",
          backgroundColor: "white",
          padding: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          marginLeft: "10px",
        }}
      >
        <form
          className="theme-form row "
          id="config"
          onSubmit={savePlantConfigs}
        >
          <div
            style={{
              borderStyle: "solid",
              padding: "0px",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderWidth: "3px",
              borderColor: "lightgrey",
              borderRadius: "10px",
              marginTop: "",
              paddingLeft: "10px",
            }}
            className="float-right col-sm-12 d-flex flex-column mt-0 pl-0 pr-0"
          >
            <h6
              className="mb-0 mt-2 pl-2"
              style={{
                fontWeight: "bold",
                color: "black",
                padding: "0",
                margin: "0",
              }}
            >
              General Settings
            </h6>
            {Object.keys(uppOpt).length > 0 ? (
              <div
                className="d-flex flex-row mt-10p"
                style={{ marginTop: "10px" }}
              >
                <PWConfigL1 data={uppOpt} colLength={4} />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className=" ml-0" style={{ marginLeft: "0px" }}>
            <div
              className="col-sm-4 ml-0 mt-1 pl-3"
              style={{
                borderStyle: "solid",
                borderWidth: "3px",
                borderColor: "lightgrey",
                borderRadius: "10px",
                paddingBottom: "11px",
              }}
            >
              <h6
                className="mb-3 mt-3"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Planning / Optimization Settings
              </h6>
              {Object.keys(lowOpt1).length > 0 ? (
                <PWConfigL1 data={lowOpt1} colLength={12} />
              ) : (
                ""
              )}
            </div>
            <div
              className="col-sm-4 ml-0 mt-1 "
              style={{
                borderStyle: "solid",
                borderWidth: "3px",
                borderColor: "lightgrey",
                borderRadius: "10px",
                paddingBottom: "20px",
                height: "98%",
              }}
            >
              <h6
                className="mb-3 mt-3 mr-3"
                style={{ fontWeight: "bold", color: "black" }}
              >
                Vehicle Fill Rate (VFR) Settings
              </h6>
              <div style={{ padding: "0", margin: "0" }}>
                {Object.keys(lowOpt2).length > 0 ? (
                  <PWConfigL1 data={lowOpt2} />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="col-sm-4 ml-0 mt-1"
              style={{
                borderStyle: "solid",
                borderWidth: "3px",
                borderColor: "lightgrey",
                borderRadius: "10px",
                height: "98%",
              }}
            >
              <h6
                className="mb-3 mt-3 mr-3 "
                style={{
                  fontWeight: "bold",
                  color: "black",
                  padding: "0",
                  margin: "0",
                }}
              >
                Load Planning Settings
              </h6>
              <div className="mt-0" style={{ padding: "0", margin: "0" }}>
                {Object.keys(lowOpt3).length > 0 ? (
                  <PWConfigL1 data={lowOpt3} />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="float-right col-sm-12 mt-3">
            <div className="form-group">
              <button type="submit" className="btn tbtn float-right save-btn">
                SAVE
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
