import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Constant from "../common/constants";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import GridButton from "./gridButton";
import getToolConfiguration from "./commonFunctions";
import { data } from "jquery";
import { GiTrojanHorse } from "react-icons/gi";

export default class ZoneMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      Zid: "",
      isSaveDisabled: false,
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      cityToStateArr: [],
      zonesData: [],
      animateRows: true,
      openVehicleAvailabilityModel: false,
      openMaterialConfigurationModel: false,
      show: false,
      basicTitle: "",
      basicType: "default",
      showAvailablityTable: false,
      data_type: "",
      rowId: "",
      zoneNames: [],
      location: this.props.plantLocation,
      zone: "",
      statename: "",
      city: "",
    };
  }

  async componentDidMount() {
    // console.log(this.props.plantLocation,"this.props.plantLocationthis.props.plantLocation")
    var curl = window.location.search;
    var ul = curl.split("?");
    // console.log("ul ",ul)
    // let url_type = window.location.href
    let url_type = ul[parseInt(ul.length) - 1];
    // console.log("url_type ", url_type)
    // let path_type = url_type.split('/')
    let path_type = url_type;
    let data_type;
    // let path = path_type[path_type.length-1]
    let path = url_type;

    if (path == "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    await this.setState({ data_type: data_type });
    let params = {
      data_type: data_type,
    };
    let toolparameters = {
      data_type: data_type,
    };
    let locationWiseZoneParams = {
      data_type: data_type,
      location: this.props.plantLocation,
    };
    let locationWiseCities = {
      ...params,
      location: this.props.plantLocation,
    };
    // console.log(this.props.plantLocation,"xxxxxxxxxxxcccc")
    await getToolConfiguration(toolparameters);
    await this.getZoneMappingData(locationWiseCities);
    await this.getZoneMasterDetails(locationWiseZoneParams);
    await this.getCityStateDetails(locationWiseCities);
  }

  getZoneMappingData = (params) => {
    redirectURL.post("master/getZoneMappingData", params).then((res) => {
      if (res.status === 200) {
        let mappedZones = [...res.data].reverse();
        console.log(mappedZones, "mappedZonesmappedZones");
        this.setState({
          rowData: mappedZones,
        });
      }
    });
  };
  getCityStateDetails = (params) => {
    redirectURL
      .post("master/getCityStateDetails", params)
      .then((response) => {
        if (response.status === 200) {
          let resData = response.data;
          // console.log(resData,"resData");
          const cleanedX = resData.map((item) => {
            // Remove empty strings and commas from the cities array
            const cleanedCities = item.cities.filter(
              (city) =>
                city.trim() !== "" &&
                (city.trim().slice(-1) === ","
                  ? city.trim().slice(0, -1)
                  : city.trim())
            );

            // Remove the comma at the end of each city in cleanedCities
            const formattedCities = cleanedCities.map((city) =>
              city.trim().replace(/,+$/, "")
            );

            // Return a new object with the cleaned cities array
            return { _id: item._id, cities: formattedCities };
          });

          // console.log(cleanedX,"cleanedX");
          this.setState(
            {
              cityToStateArr: cleanedX,
            }
            // , ()=>{
            //     console.log(this.state.cityToStateArr,"cityToStateArr")
            // }
          );

          let citiesArr = [];
          response.data.map((e) => {
            e.cities.map((k) => {
              if (k) {
                citiesArr.push({ value: e._id, label: k });
              }
            });
          });
          // console.log(citiesArr,"citiesArrcitiesArr")
          // let correctedNames = citiesArr.map(e => capitalizeString(e));
          let citiesList = [];
          // let citiesStateSet = new Set();
          if (citiesArr.length > 0) {
            citiesArr.map((item) => {
              citiesList.push({
                value: item.value.replace(/,/g, ""),
                label: item.label.replace(/,/g, ""),
              });
            });
            this.setState(
              {
                citiesList: citiesList.sort((a, b) =>
                  a.label.localeCompare(b.label)
                ),
              }
              // ,()=>{
              //     console.log(citiesList,"citiesList")
              // }
            );
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error); // Add this for debugging
      });
  };
  openModelForMaterialConfiguration = () => {
    this.setState((prevState) => ({
      openMaterialConfigurationModel: !prevState.openMaterialConfigurationModel,
    }));
  };

  handelMaterialConfigurationModelCancel = () => {
    this.setState((prevState) => ({
      openMaterialConfigurationModel: !prevState.openMaterialConfigurationModel,
      rowId: "",
      zone: "",
      statename: "",
      city: "",
      isSaveDisabled: false,
    }));
  };

  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };

  vehicleConfigurationInputFields = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    value = value.toUpperCase();
    this.setState({ [name]: value });
  };

  zoneChanged = (zone) => {
    this.setState(
      {
        zone: zone,
      },
      () => {
        let zoneId = this.state.zonesData.find((e) => {
          if (e.zone === this.state.zone.value) {
            return true;
          }
        });
        let Zid = zoneId.zone_id;
        this.setState({
          Zid: Zid,
        });
      }
    );
  };
  cityChanged = (e) => {
    this.setState(
      {
        city: e,
      },
      async () => {
        let respectiveStateValue = await findStateByCity(
          e,
          this.state.cityToStateArr
        );
        if (respectiveStateValue === "") {
          this.setState({
            isSaveDisabled: true,
          });
        } else {
          this.setState({
            isSaveDisabled: false,
          });
        }
        this.setState({
          statename: respectiveStateValue,
        });
      }
    );
  };

  getZoneMasterDetails = async (param) => {
    let zoneNames = this.state.zoneNames;
    await redirectURL
      .post("master/getZoneMasterDetails", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          this.setState({
            zonesData: response.data.zonesData,
          });
          let zonesData = response.data.zonesData;
          if (zonesData.length > 0) {
            zonesData.map((item) => {
              zoneNames.push({ value: item.zone, label: item.zone });
            });
            await this.setState({
              zoneNames: zoneNames,
            });
          }
        }
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  saveZoneMapping = async (params) => {
    console.log(params, "qqqqqqqqqqqqqqqqqqqq");
    await redirectURL
      .post("master/saveZoneMapping", params)
      .then((response) => {
        if (response.data.status == "success") {
          let data_type = this.state.data_type;
          let zoneId = this.state.Zid;
          let state = this.state.statename;
          let city = this.state.city;
          let location = this.state.location;
          let zone = this.state.zone;

          let params = {
            zone: zone.value,
            state: state,
            city: city.value,
            zoneId: zoneId,
            location_code: location.value,
            location_name: location.label,
            data_type: data_type,
          };

          this.setState({
            openMaterialConfigurationModel: false,
            statename: "",
            zone: "",
            rowId: "",
            show: true,
            city: "",
            basicTitle: response.data.message,
            basicType: "success",
          });
          let paramsForUpdatedMappingData = {
            data_type: this.state.data_type,
            location: this.props.plantLocation,
          };
          this.getZoneMappingData(paramsForUpdatedMappingData);
        } else if (response.data.status == "danger") {
          this.setState({
            openMaterialConfigurationModel: false,
            statename: "",
            zone: "",
            rowId: "",
            show: true,
            city: "",
            basicTitle: response.data.message,
            basicType: "danger",
          });
        } else {
          this.setState({
            show: true,
            basicTitle: "Failed to save the data",
            basicType: "danger",
          });
        }
      });
  };

  deleteZoneMappingDetails = (propData) => {
    let data = propData.data;
    let data_type = this.state.data_type;

    let params = {
      delete_id: data._id,
    };
    console.log(data, "deletePropData");
    console.log(params, "deletePropData");
    redirectURL
      .post("master/deleteZoneMappingDetails", params)
      .then((response) => {
        if (response.data.status == "success") {
          let paramsForUpdatedRrcdsAfterDeleting = {
            data_type: data_type,
            location: this.props.plantLocation,
          };
          this.setState({
            show: true,
            basicTitle: "Deleted Successfully",
            basicType: "default",
          });
          this.getZoneMappingData(paramsForUpdatedRrcdsAfterDeleting);
        } else {
          this.setState({
            show: true,
            basicTitle: "Failed to delete the data",
            basicType: "danger",
          });
        }
      });
  };

  formVehicleConfiguration = (e) => {
    e.preventDefault();
    let rowId = this.state.rowId;
    let data_type = this.state.data_type;
    let zoneId = this.state.Zid;
    let state = this.state.statename;
    let city = this.state.city;
    let location = this.state.location;
    let zone = this.state.zone;

    let params = {
      zone: zone.value,
      rowId: rowId,
      state: state,
      city: city.label,
      zoneId: zoneId,
      location_code: location.value,
      location_name: location.label,
      data_type: data_type,
    };
    // console.log(params,"paramsssss")
    //    if(location.value != null && zone.value != null){
    // console.log(this.state.rowData,"rowData");
    const filterdData = this.state.rowData.filter((e) => {
      if (e.city == params.city) {
        return true;
      }
    });
    if (location.value != null && zone && city) {
      if (filterdData.length > 0) {
        this.setState({
          show: true,
          basicTitle: "City is already mapped",
          basicType: "danger",
        });
      } else {
        this.saveZoneMapping(params);
      }
    } else {
      if (zone.value == null) {
        this.setState({
          show: true,
          basicTitle: "Please Select Zone",
          basicType: "danger",
        });
      }
      if (city.value == null) {
        this.setState({
          show: true,
          basicTitle: "Please Select City",
          basicType: "danger",
        });
      }
    }
    //    else{
    // if(zone.value == null){
    //     this.setState({
    //         show:true,
    //         basicTitle:"Please Select Zone",
    //         basicType:"danger"
    //     })
    // }
    // else{
    //     this.setState({
    //         show:true,
    //         basicTitle:"Please add "+ Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION + " First",
    //         basicType:"danger"
    //     })
    //     }
  };

  editzonemapping = async (propdata) => {
    let data = propdata.data;
    // console.log(propdata,"propData", propdata.data, "propData.data");
    await this.setState(
      {
        statename: data.state,
        zone: { label: data.zone, value: data.zone },
        rowId: data._id,
        city: { label: data.city, value: data.city },
      }
      // ,()=>{
      //     console.log(data.city,"data.city")
      // }
    );
    this.openModelForMaterialConfiguration();
  };
  render() {
    var toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    var auto_clustering_type = 0;
    if (toolConfig != undefined && toolConfig != "") {
      if (toolConfig.length > 0) {
        if (toolConfig[0].cluster_based_on_proximite === 1) {
          auto_clustering_type = 1;
        }
        if (toolConfig[0].cluster_based_on_demands === 1) {
          auto_clustering_type = 2;
        }
        if (toolConfig[0].cluster_based_on_demand_distance_factor === 1) {
          auto_clustering_type = 3;
        }
      }
    }
    var colsDefs = [
      {
        headerName: "Actions",
        field: "cluster",
        width: "80",
        pinned: "left",
        params: { buttonName: "Edit", onClickFunction: this.editzonemapping },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: "",
        field: "cluster",
        width: "80",
        pinned: "left",
        params: {
          buttonName: "Delete",
          iconName: "fa fa-trash",
          onClickFunction: this.deleteZoneMappingDetails,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: "State",
        field: "state",
        width: "140",
      },
      {
        headerName: "City",
        field: "city",
        width: "140",
      },
      {
        headerName: "zone",
        field: "zone",
        width: "160",
      },
    ];
    // let data = this.state.rowData
    // let locValue = this.state.location.value

    // let filteredData = data.filter(item => item.location_code == locValue)
    // let filteredData = this.state.rowData;
    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <div class="row mb-20p pt-20p">
          <div className="col-sm-12 d-flex">
            <div
              className={
                this.state.showAvailablityTable
                  ? "col-sm-7 "
                  : "col-sm-12 " + "  d-inline"
              }
            >
              {/* {(filteredData.length == 0)? */}
              <h5 className="fbold  d-flex justify-content-between">
                <span>Zone Mapping</span>
                <div className="float-right">
                  <a
                    href="javascript:;"
                    onClick={this.openModelForMaterialConfiguration}
                    className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p"
                  >
                    + Add Zone Mapping
                  </a>
                </div>
              </h5>
              {/* :""} */}
              {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                                    <Select
                                        placeholder={"Select"}
                                        onChange={this.locationChanged}
                                        name="location"
                                        value={this.state.location}
                                        options={this.state.locationNames} />

                                </div>
                            </div> */}

              <div
                id="myGrid"
                style={{ width: "100%", height: "70vh" }}
                className="col-sm-12 ag-theme-balham dropdown"
              >
                <AgGridReact
                  modules={this.state.modules}
                  rowData={this.state.rowData}
                  columnDefs={colsDefs}
                  gridOptions={{ context: { componentParent: this } }}
                  defaultColDef={this.state.defaultColDef}
                  frameworkComponents={this.state.frameworkComponents}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  statusBar={this.state.statusBar}
                  sideBar={this.state.sideBar}
                  enableRangeSelection={true}
                />
              </div>
            </div>

            {/* { this.state.showAvailablityTable ?
                    <div className='col-sm-5 d-inline'>
                    
                    <h5 className='fbold white d-flex justify-content-between'>
                            <span>{Constant.MASTER_VEHICLE_DATA_AVAILABILITY_HEADER_NAME}</span>
                            <div className='float-right'>
                            <a href="javascript:;" onClick={this.openModelForVehicleAvailability} className="f18 float-right themefont fbold mr-10p mb-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                             */}
            {/* <span className="float-right">
                            <a href="javascript:;"  onClick={this.openUploadOptionForPickupDrop} className="btn btn-warning f12 mr-10p">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}</a>
                            </span> */}
            {/* </div>    
                        </h5>
                        
                        <VehiclesAvailability
                        availabilityData = {this.state.availabilityData}/>
                        <span className="float-right">
                            <button onClick={this.hideVehicleAvailabilityTable} className="btn btn-warning f12 mr-15p mt-5p">Close</button>
                            </span>
                    </div>
                    :<div/>} */}
          </div>
        </div>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.openMaterialConfigurationModel}
          onClose={this.handelMaterialConfigurationModelCancel}
          classNames={{ modal: "pickupdrop-modelClass55vh" }}
        >
          <div className="col-sm-12">
            <div>
              <h4>Zone Mapping</h4>
            </div>
            <form
              className="theme-form"
              onSubmit={this.formVehicleConfiguration}
            >
              <div className="row number">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION
                      }
                    </label>
                    <Select
                      isDisabled
                      name="location"
                      value={this.state.location}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">State</label>
                    <input
                      required
                      type="text"
                      className="forminp form-control"
                      id="statename"
                      name="statename"
                      value={this.state.statename}
                      // onChange={this.vehicleConfigurationInputFields}
                      disabled={true}
                      placeholder=""
                      autoComplete="off"
                    />
                    <span>
                      {this.state.isSaveDisabled ? (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          * This city is not mapped to any state
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Zone</label>
                    <Select
                      placeholder={"Select"}
                      onChange={this.zoneChanged}
                      name="zone"
                      value={this.state.zone}
                      options={this.state.zoneNames}
                      maxMenuHeight={"170px"}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">Cities</label>
                    <Select
                      placeholder={"Select"}
                      onChange={this.cityChanged}
                      name="city"
                      value={this.state.city}
                      options={this.state.citiesList}
                      maxMenuHeight={"120px"}
                    />
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label className="col-form-label f12">Zone</label>
                                    <select name="zone" id="zone" value = {this.state.zone}
                                        required={true}
                                        className={"forminp form-control"}
                                        onChange={this.vehicleConfigurationInputFields}>
                                        <option value="" disabled selected>Select</option>
                                        <option value="SOUTH">SOUTH</option>
                                        <option value="WEST">WEST</option>
                                        <option value="EAST">EAST</option>
                                        <option value="NORTH EAST">NORTH EAST</option>
                                        <option value="NORTH">NORTH</option>
                                    </select>
                                    
                                </div>
                            </div> */}
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn tbtn float-right save-btn"
                  disabled={this.state.isSaveDisabled}
                  id="saveGeo"
                >
                  SAVE
                </button>
                <button
                  type="button"
                  className="btn tbtn float-right cancel-btn"
                  onClick={this.handelMaterialConfigurationModelCancel}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </>
    );
  }
}
function capitalizeString(str) {
  let words = str.toLowerCase().split(" ");
  let capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
}
function findStateByCity(cityName, dataArray) {
  // console.log(cityName.label,"cityNamecityName")
  // console.log(dataArray,"dataArraydataArray")

  for (let i = 0; i < dataArray.length; i++) {
    if (
      dataArray[i]._id === cityName.value &&
      dataArray[i].cities.includes(cityName.label.trim().replace(/,+$/, ""))
    ) {
      // console.log(dataArray[i]._id,"dataArray[i]._id")
      return dataArray[i]._id;
    }
  }
  return null; // Return null if city name is not found
}
