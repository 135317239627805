import React, { Component } from "react";
import redirectURL from '../redirectURL';
import SweetAlert from 'react-bootstrap-sweetalert';

import Level1 from "./configurations/confingL1";
import Select from 'react-select';
import { logFormChanges } from './auditLogsFunction'
import ConfigSummaryDemo from "./configurations/configsForDemo";

class PlantWiseConfigurations extends Component {
    constructor(props) {
        super(props)
        this.state = {
            records: {},
            dispatchChecked: true,
            show: false,
            basicType: 'default',
            basicTitle: '',
            dispatchInboundChecked: true,
            dispatchData: "show-m",
            dispatchOutboundChecked: true,
            dispatchConfigsInbound: "show-m",
            dispatchConfigsOutbound: "show-m",
            locationNames: [],
            location: '',
            setVehicleCheckList: false,
            plantConfigs: [],
            previousData:[]
        }
    }
    componentDidMount = async () => {


        redirectURL.post("/master/getPlantwiseConfigs")
            .then(async (response) => {
                console.log(response.data[0], "plantConfigs")
                await this.setState({ 
                    plantConfigs: response.data[0],
                    previousData: JSON.parse(JSON.stringify(response.data[0]))
                 })


            })
        this.boundTypeLocations({ data_type: 2 })


    }


    boundTypeLocations = async (param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = this.state.locationNames;
        // console.log(location_access, locationNames, 'hello')
        await redirectURL.post('master/getBoundTypeLocations', param)
            .then(async (response) => {
                // console.log(response,response.data)
                if (response.data.status == 'success') {
                    let boundLocations = response.data.boundLocations
                    if (boundLocations.length > 0) {
                        boundLocations.map(item => {
                            if (location_access != null) {
                                if (location_access.includes(item.location_code)) {
                                    locationNames.push({
                                        value: item.location_code,
                                        label: item.location_name
                                    })

                                }
                            } else {
                                locationNames.push({
                                    value: item.location_code,
                                    label: item.location_name
                                })
                            }
                        })

                        if (locationNames.length > 0) {
                            await this.setState({
                                locationNames: locationNames,
                                location: locationNames[0]
                            })
                        } else {
                            await this.setState({
                                locationNames: [{ label: 'Please Add Data', value: 'no_data' }],
                                location: [{ label: 'Please Add Data', value: 'no_data' }]
                            })
                        }
                    }
                }
                this.setState({ loadshow: 'show-n' })
                // console.log(this.state.locationNames, 'hello')
            })
            .catch(function (error) {
                var errDetails = {
                    url: "master/getBoundTypeLocations",
                    screen: 'Vehicle Availability Configurations',
                    error,
                }
                redirectURL.post("/master/logErrorsForApiCalls", errDetails)

            })


    }

    handleDispatchInboundChange = (checked) => {
        var dispatchConfigsInbound = "show-n";
        if (checked == true) {
            dispatchConfigsInbound = "";
            this.setState({
                dispatchInboundChecked: checked,
                dispatchConfigsInbound: dispatchConfigsInbound
            })
        } else {
            this.setState({
                dispatchInboundChecked: checked,
                dispatchConfigsInbound: dispatchConfigsInbound
            })
        }
    }
    handleDispatchOutboundChange = (checked) => {
        var dispatchConfigsOutbound = "show-n";
        if (checked == true) {
            dispatchConfigsOutbound = "";
            this.setState({
                dispatchOutboundChecked: checked,
                dispatchConfigsOutbound: dispatchConfigsOutbound
            })
        }
        else {
            this.setState({
                dispatchOutboundChecked: checked,
                dispatchConfigsOutbound: dispatchConfigsOutbound
            })
        }
    }
    handleDispatchChange = (checked) => {
        var dispatchConfigs = "show-n";
        var cnf = window.confirm("Configurations will be Disabled/Altered, Are you sure you want to proceed ?");
        if (cnf === true) {
            if (checked == true) {
                dispatchConfigs = "";
                this.setState({ dispatchChecked: checked, dispatchConfigs: dispatchConfigs });
            }

            this.setState({ dispatchChecked: checked, dispatchConfigs: dispatchConfigs });
        }
    }


    locationChanged = (location) => {
        this.setState({
            location: location
        })
        // , ()=>{
        //     console.log(this.state.location, "location selected");
        //     if(this.state.location.value === "LK1SLK02"){
        //         this.setState({
        //             setVehicleCheckList: true
        //         })
        //         let storedData = localStorage.getItem('qry');

        //         // Step 2: Parse the JSON string into a JavaScript object
        //         let parsedData = JSON.parse(storedData);
                
        //         // Step 3: Update the necessary value
        //         parsedData.plant_location_code = this.state.location.value; // Replace 'newLocationCode' with the desired value
                
        //         // Step 4: Stringify the updated object back into a JSON string
        //         let updatedData = JSON.stringify(parsedData);
                
        //         // Step 5: Save the updated JSON string back into localStorage
        //         localStorage.setItem('qry', updatedData);
        //     }else {
        //         this.setState((prevState) => ({
        //             setVehicleCheckList: !prevState.setVehicleCheckList
        //         }))
        //     }
        // })
    }
    saveOutboundConfigs = () => {

        var previousData = this.state.previousData
        var outboundRec = { ...this.state.plantConfigs }
        var location = this.state.location.value


        outboundRec = outboundRec[location].Outbound
        var previousOutboundRec = previousData[location].Outbound

        var keys = Object.keys(outboundRec)
        keys.map((item1) => {
            var data1 = outboundRec[item1]
            if (data1.value == 0) {
                var keys1 = Object.keys(data1)
                keys1.map((item2) => {
                    if (item2 !== 'type' && item2 !== 'disabled' && item2 !== "value" && item2 !== 'name' && item2 !== 'id') {
                        var data2 = data1[item2]
                        data2.value = 0
                        var keys2 = Object.keys(data2)
                        keys2.map((item3) => {
                            if (item3 !== 'type' && item3 !== 'disabled' && item3 !== "value" && item3 !== 'name' && item3 !== 'id') {
                                var data3 = data2[item3]
                                data3.value = 0
                                // console.log(data3,'hello')
                                var keys3 = Object.keys(data3)
                                keys3.map((item4) => {
                                    if (item4 !== 'type' && item4 !== 'disabled' && item4 !== "value" && item4 !== 'name' && item4 !== 'id') {
                                        var data4 = data3[item4]
                                        data4.value = 0
                                    }
                                })
                            }
                        })

                    }

                })
            } else {
                var keys1 = Object.keys(data1)
                keys1.map((item2) => {
                    if (item2 !== 'type' && item2 !== 'disabled' && item2 !== "value" && item2 !== 'name' && item2 !== 'id') {
                        var data2 = data1[item2]
                        if (data2.value == 0) {
                            var keys2 = Object.keys(data2)
                            keys2.map((item3) => {
                                if (item3 !== 'type' && item3 !== 'disabled' && item3 !== "value" && item3 !== 'name' && item3 !== 'id') {
                                    var data3 = data2[item3]
                                    data3.value = 0
                                    var keys4 = Object.keys(data3)
                                    keys4.map((item4) => {
                                        if (item4 !== 'type' && item4 !== 'disabled' && item4 !== "value" && item4 !== 'name' && item4 !== 'id') {
                                            var data4 = data3[item4]
                                            data4.value = 0
                                        }
                                    })
                                }
                            })
                        }

                    }

                })
            }
        })

        let logParams = {
            "user_name": localStorage.getItem("username"),
            "useremail": localStorage.getItem("email"),
            "client": localStorage.getItem("client"),
            "screen": "Plant wise configuration screen",
            "location_code": location,
            "activity": {}
        }
        
        var latestValues=this.getConfigsKeyvaluesObj(outboundRec)
        var previousValues=this.getConfigsKeyvaluesObj(previousOutboundRec)
        // console.log(outboundRec,'ginal')
        outboundRec.location = location
        outboundRec.name = 'Outbound'
        redirectURL.post('master/savePlantWiseConfigs', outboundRec)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        show: true,
                        basicTitle: "Data Saved successfully",
                        basicType: "success"
                    })
                    // window.location.reload()
                    logFormChanges(logParams, latestValues,previousValues);
            }
        })
    }

    getConfigsKeyvaluesObj=(data)=>{

        var query = data
        var params = {}
        Object.keys(query).map((item1) => {
            params[query[item1].name] = query[item1].value
            var data1 = query[item1]
            if (Object.keys(data1).length > 0) {
                Object.keys(data1).map((item2) => {
                    var data2 = data1[item2]
                    params[data2.name] = data2.value
                    var keys2 = Object.keys(data2)
                    if (keys2.length > 0) {
                        keys2.map((item3) => {
                            var data3 = data2[item3]
                            if (data3.name === 'max_no_of_trucks' && data3.value < 4) {
                                data3.value = 4
                            }
                            params[data3.name] = data3.value
                            var keys3 = Object.keys(data3)
                            if (keys3.length > 0) {
                                keys3.map((item4) => {
                                    var data4 = data3[item4]
                                    if (data4.name === 'gps_truck_location_vicinity' && data4.value < 1000) {
                                        data4.value = 1000

                                    }
                                    params[data4.name] = data4.value
                                })
                            }
                        })


                    }
                })

            }
        })
        delete params.undefined

        return params
    }
    render() {
        const closeAlert = () => {
            this.setState({ show: false })
            window.location.reload();
        }
        var records = this.state.records

        records = this.state.plantConfigs
        var location = this.state.location.value
        records = records[location]
        if (records === undefined) {
            records = {}
            
        }
        var issavebtnDisable = localStorage.getItem("user_type") === "admin";
    
        var ownerMails = [
            "nanda@enmovil.in",
            "ravi@enmovil.in",
            "nanda@enmovil.ai",
            "ravi@enmovil.ai",
            "venkat.moganty@enmovil.in",
            "venkatesh.jalla@enmovil.in",
            "babujani@enmovil.in",
            "Yugi@enmovil.in",
            "Shekhar.bhoj@enmovil.in",
            "Mousumi.mahapatra@enmovil.in",
            "Sushmita.k@enmovil.in",
            "Bharat.satya@enmovil.in",
            "jagdeesan.v@enmovil.ai",
            "dhaval.bhoyar@enmovil.in",
            "prafulla.kumar@enmovil.in",
            "soubhagya.pattanaik@enmovil.in",           
        ];
        var email = localStorage.getItem("email");
        var mailCheck = ownerMails.includes(email);
        var nestleUserConfigs = [
          "Prioritize Local Loads",
          "City Based Entry Restriction",
          // "Follow Telescopic Routes",
          "Consider Vehicle Break Times (Night Driving)",
          "Consider Dropping Distant Smaller Loads",
          "Consider Holiday Restrictions",
          "Consider Realtime Vehicle Available Times",
          "Plan Spot Vehicles",
          "Consider Transporter SOB",
          "Number of Loading Docks",
          // "Max No of Customers Per Vehicle",
          "Max Local No of Customers Per Vehicle",
          "Max Upcountry No of Customers Per Vehicle",
          "Prioritize Same Dealers First in a Vehicle",
          "Prioritize Multi City Dealers First in a Vehicle",
          "Use Volume for Vehicle Fill Rate (VFR)",
          "Use Weight for Vehicle Fill Rate (VFR)",
          "Min VFR Per Vehicle Type",
          "Min VFR Per Spot Vehicle",
          "MOQ for Planning",
          "Use Dynamic Planning Window",
          "Number of Days to Use Planning Window",
          "Optimization Type*",
          "Planning Type",
          "Month End Planning Start Day",
        ];
        // console.log("first", email.includes(".nestle.com") && records.Outbound);
        var outboundRecords = {};
        if ((email.toLowerCase()).includes(".nestle.com") && records.Outbound) {
          nestleUserConfigs = nestleUserConfigs.filter((key) =>
            records.Outbound.hasOwnProperty(key)
          );
          nestleUserConfigs.map((item) => {
            outboundRecords[item] = records.Outbound[item];
            return true;
          });
        } else {
          outboundRecords = records.Outbound;
        }
        return (
            < >
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={closeAlert}>
                </SweetAlert>
                <div className="row mb-20p pt-20p">
                    <div className="col-sm-12 d-flex">
                        <div className={"col-sm-12   d-inline"}>
                            <h5 className='fbold  d-flex justify-content-between'>
                                <span>Plant Wise Configurations</span>
                            </h5>
                        </div>
                    </div>
                </div>

                <div className="card container d-flex align-items-center">
                    <div className="card-body card2 col-md-12">
                        <div className="col-md-12 col-sm-12 ml-0 pl-0">
                            <div className="form-group col-md-2 col-sm-12 ml-0 pl-0">
                                <label className="col-form-label f12 ml-0 pl-0">Pickup Location</label>
                                <Select
                                    placeholder={"Select"}
                                    onChange={this.locationChanged}
                                    name="location"
                                    value={this.state.location}
                                    options={this.state.locationNames}
                                    className="col-sm-12 pl-0"
                                />
                            </div>
                        </div>
                        {mailCheck?<div>
                            {Object.keys(records).length > 0 ? <ConfigSummaryDemo data={outboundRecords} /> : ''}
                        </div>:
                        <div className="container d-flex align-items-center ">
                            <div className="row">
                                <div className="float-right">
                                    {Object.keys(records).length > 0 ? <Level1 data={outboundRecords} /> : ''}
                                </div>
                                <button type="button" disabled={!issavebtnDisable} className="btn btn-danger float-left" onClick={this.saveOutboundConfigs}>Save Plant Wise Configurations</button>
                            </div>
                        </div>}
                    </div>
                </div>

            </>
        )
    }
}


export default PlantWiseConfigurations
