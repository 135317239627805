import React, { useEffect, useState } from "react";

function PowerBIReport({ url }) {
  const [scaleClass, setScaleClass] = useState("iframe-bi-100");

  useEffect(() => {
    const handleScale = () => {
      const scale = window.devicePixelRatio;
      if (scale > 1.25) {
        setScaleClass("iframe-bi-100");
      } else {
        setScaleClass("iframe-bi-125");
      }
    };

    // Detect initial scale and adjust on resize
    handleScale();
    window.addEventListener("resize", handleScale);

    return () => {
      window.removeEventListener("resize", handleScale);
    };
  }, []);
  return (
    <div>
      <iframe
        title="Report Section"
        src={url}
        className={scaleClass}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default PowerBIReport;
