import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import GridButton from "../gridButton";
import React, { Component } from "react";
import redirectURL from "../../redirectURL";
import $ from "jquery";
import * as XLSX from "xlsx";
import CSVFileValidator from "csv-file-validator";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getHyphenDDMMMYYYYHHMM,
  getHyphenYYYYMMDDHHMMSS,
} from "../../common/utils";
import StaticButton from "../static-button";
import NAButton from "../naButton";
// import Select from "react-select";
var moment = require("moment");

export default class ActualDataFO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        NAButton: NAButton,
        GridButton: GridButton,
        StaticButton: StaticButton,
      },
      rowData: [],
      detailCellRendererParams: {},
      locationNames: [],
      location: "",
      runNewPlanTab: "activet",
      vehicleAvailabilityTab: "",
      runNewPlanData: true,
      vehicleAvailabilityData: false,
      loadshow: "show-m",
      overly: "show-m",
      showSlideBlockUpload: "",
      loadFile: "",
      systemPlanning: 1,
      manualPlanning: 0,
      vehicleDataDownld: false,
      vehiclesFile: "",
      show: false,
      basicTitle: "",
      basicType: "default",
      conPlanData: [],
      inputDetails: [],
      showInputSlider: "sidebarcls",
      vehicleConfigs: [],
      allconsolidatedTrnsxId: [],
      is_sunday_planning_enabled: 0,
      inputXlFile: false,
      plantCodes: [],
      simulationTime: "",
      //   fromDate: "",
      //   toDate: "",
      toDate: moment.parseZone().utcOffset("+05:30").format("YYYY-MM-DD"),
      fromDate: moment
        .parseZone()
        .subtract(1, "months")
        .utcOffset("+05:30")
        .format("YYYY-MM-DD"),
      processErrShow: 0,
      processErrMsg: "",
      droppedList: [],
      foCounterData: "",
      showSlideBlockCounters: "",
    };
  }
  async componentDidMount() {
    // var toDate = moment().format("YYYY-MM-DD");
    // var fromDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    // console.log(toDate, "didmout", fromDate);
    // $("#fromDate").val(fromDate);
    // $("#toDate").val(toDate);
    await this.setState({
      loadshow: "show-n",
      overly: "show-n",
      // toDate,
      // fromDate,
    });
    await this.getActualDataFO();
    await this.boundTypeLocations();
    await this.removeDatepickerClass();
  }

  getActualDataFO = async () => {
    // var fromDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    // var toDate = moment().format("YYYY-MM-DD");
    var { fromDate, toDate } = this.state;
    await redirectURL
      .post("actualdatafo/getActualDataFO", { fromDate, toDate })
      .then(async (response) => {
        let conPlanData = response.data.sort(GetSortDescOrder("createDate"));
        this.setState({ conPlanData });
      })
      .catch(function (error) {
        console.log(error);

        var errDetails = {
          url: "/actualdatafo/getActualDataFO",
          error,
          screen: "fo details",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  hideSlideBlock = () => {
    this.setState({
      showSlideBlockUpload: "",
      overly: "show-n",
      loadFile: "",
      showInputSlider: "",
      showSlideBlockCounters: "",
    });
    $("#uploadFile").val("");
    $("#vehiclesFile").val("");
    document.getElementById("inValidDataInfo").innerHTML = "";
    document.getElementById("processingtextId").setAttribute("hidden", true);
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "FO Details",
      activity: "Clicked on Cancel Button",
      event: "Closed Upload File Slider",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onClickUploadFile = () => {
    this.setState({ showSlideBlockUpload: "slide25", overly: "show-m" });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "FO Details",
      activity: "clicked on Upload Input file Button",
      event: "Opened Upload file Slider",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  csvFileValidationsForInputFile = (csvData) => {
    var { plantCodes } = this.state;
    const config = {
      headers: [
        {
          name: "Assigned FO",
          inputName: "fo_number",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "ERP delivery number",
          inputName: "order_number",
          required: true,
          unique: true,
          uniqueError: function (headerName) {
            return `${headerName} is not unique`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Source Location Name",
          inputName: "plant_name",
          required: true,
          validate: function (code) {
            return isPlantValid(code, plantCodes);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Dest Location City",
          inputName: "location_city",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },

        {
          name: "Consignee",
          inputName: "location_code",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Customer Name",
          inputName: "location_name",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Quantity",
          inputName: "quantity",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Gross Weight (VU)",
          inputName: "weight",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Gross Volume (VU)",
          inputName: "volume",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Carrier",
          inputName: "transporter_code",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Carrier description",
          inputName: "transporter_name",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Means of Transport",
          inputName: "means_of_transport",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Registration Number",
          inputName: "vehicle_no",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Vehicle Type",
          inputName: "vehicle_type",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Execution Date",
          inputName: "execution_date",
          required: true,
          // requiredError: function (headerName, rowNumber, columnNumber) {
          //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`,
          // },
          validate: function (date) {
            return isDateFormatValid(date);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
      ],
    };
    CSVFileValidator(csvData, config)
      .then(async (csvData) => {
        document.getElementById("bulkUploadBtn").type = "button";
        document.getElementById("bulkUploadBtn").classList.remove("btn-info");
        document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
        document.getElementById("inValidDataInfo").innerHTML = "";

        if (csvData.inValidData.length > 0) {
          $("#uploadFile").val("");
          $("#vehiclesFile").val("");
          document.getElementById("bulkUploadBtn").type = "button";
          document.getElementById("bulkUploadBtn").classList.remove("btn-info");
          document
            .getElementById("bulkUploadBtn")
            .classList.add("btn-secondary");
          document
            .getElementById("processingtextId")
            .setAttribute("hidden", true);
          let invalidData = csvData.inValidData;
          let element = document.getElementById("inValidDataInfo");
          invalidData.map((item) => {
            let row, column;
            if (item.rowIndex == undefined) {
              row = "NA";
            } else {
              row = item.rowIndex;
            }
            if (item.columnIndex == undefined) {
              column = "NA";
            } else {
              column = item.columnIndex;
            }

            element.innerHTML +=
              "Column : " +
              column +
              "," +
              " Row : " +
              row +
              "--" +
              item.message +
              "<br></br>";
          });
          let throwMsg = 0;
          invalidData.map((itm) => {
            if (itm.columnIndex == 15) {
              throwMsg = 1;
            }
          });
        } else {
          document.getElementById("bulkUploadBtn").type = "submit";
          document
            .getElementById("bulkUploadBtn")
            .classList.remove("btn-secondary");
          document.getElementById("bulkUploadBtn").classList.add("btn-info");
          document
            .getElementById("processingtextId")
            .setAttribute("hidden", true);
        }
      })
      .catch((err) => {
        var errDetails = {
          url: "CSVFileValidator",
          err,
          screen: "FO Details",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  validateAllValues = (jsonData) => {
    var { plantCodes } = this.state;
    var inValidData = [];
    jsonData.map((rec, idx) => {
      if (rec["Assigned FO"] == "" || rec["Assigned FO"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Assigned FO is required",
        });
      }
      if (
        rec["ERP delivery number"] == "" ||
        rec["ERP delivery number"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 2,
          message: "ERP delivery number is required",
        });
      }
      if (
        rec["Source Location Name"] == "" ||
        rec["Source Location Name"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 3,
          message: "Source Location Name  is required",
        });
      } else if (!isPlantValid(rec["Source Location Name"], plantCodes)) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 3,
          message: "Source Location Name is not Valid",
        });
      }
      if (
        rec["Dest Location City"] == "" ||
        rec["Dest Location City"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 4,
          message: "Dest Location City  is required",
        });
      }
      if (rec["Consignee"] == "" || rec["Consignee"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 5,
          message: "Consignee  is required",
        });
      }
      if (rec["Customer Name"] == "" || rec["Customer Name"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 6,
          message: "Customer Name  is required",
        });
      }
      // console.log(!(isDateFormatValidforXLSX(rec["Invoicing Date"]) || isDateFormatValid(rec["Invoicing Date"])))
      // if (rec["Invoicing Date"].includes("-")) {
      //   if (
      //     rec["Invoicing Date"] === "" ||
      //     rec["Invoicing Date"] === undefined ||
      //     !isDateFormatValid(rec["Invoicing Date"])
      //   ) {
      //     inValidData.push({
      //       rowIndex: idx + 2,
      //       columnIndex: 1,
      //       message: "Invoicing Date is required",
      //     });
      //   }
      // } else {
      //   if (
      //     rec["Invoicing Date"] === "" ||
      //     rec["Invoicing Date"] === undefined ||
      //     !isDateFormatValidforXLSX(rec["Invoicing Date"])
      //   ) {
      //     inValidData.push({
      //       rowIndex: idx + 2,
      //       columnIndex: 1,
      //       message: "Invoicing Date is required",
      //     });
      //   }
      // }
      if (rec["Quantity"] == "" || rec["Quantity"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 7,
          message: "Quantity  is required",
        });
      }
      if (
        rec["Gross Weight (VU)"] == "" ||
        rec["Gross Weight (VU)"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 8,
          message: " Gross Weight (VU) is required",
        });
      }
      if (
        rec["Gross Volume (VU)"] == "" ||
        rec["Gross Volume (VU)"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 9,
          message: " Gross Volume (VU) is required",
        });
      }
      if (rec["Carrier"] == "" || rec["Carrier"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 10,
          message: "Carrier  is required",
        });
      }
      if (
        rec["Carrier description"] == "" ||
        rec["Carrier description"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 11,
          message: "Carrier description  is required",
        });
      }
      if (
        rec["Means of Transport"] == "" ||
        rec["Means of Transport"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 12,
          message: "Means of Transport  is required",
        });
      }
      if (
        rec["Registration Number"] == "" ||
        rec["Registration Number"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 13,
          message: "Registration Number  is required",
        });
      }
      if (rec["Vehicle Type"] == "" || rec["Vehicle Type"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 14,
          message: "Vehicle Type  is required",
        });
      }
      if (rec["Execution Date"] == "" || rec["Execution Date"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 15,
          message: "Execution Date is required",
        });
      } else if (!isDateFormatValid(rec["Execution Date"])) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 15,
          message: "Execution Date Format is not Valid",
        });
      }
    });
    document.getElementById("inValidDataInfo").innerHTML = "";
    if (inValidData.length > 0) {
      $("#uploadFile").val("");
      document.getElementById("bulkUploadBtn").type = "button";
      document.getElementById("bulkUploadBtn").classList.remove("btn-info");
      document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
      document.getElementById("processingtextId").setAttribute("hidden", true);
      let invalidData = inValidData;
      let element = document.getElementById("inValidDataInfo");
      invalidData.map((item) => {
        let row, column;
        if (item.rowIndex == undefined) {
          row = "NA";
        } else {
          row = item.rowIndex;
        }
        if (item.columnIndex == undefined) {
          column = "NA";
        } else {
          column = item.columnIndex;
        }

        element.innerHTML +=
          "Column : " +
          column +
          "," +
          " Row : " +
          row +
          "--" +
          item.message +
          "<br></br>";
      });
      let throwMsg = 0;
      invalidData.map((itm) => {
        if (itm.columnIndex == 15) {
          throwMsg = 1;
        }
      });
      if (throwMsg) {
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle: "Please Input Valid Data",
        });
      }
      return false;
    } else {
      document.getElementById("bulkUploadBtn").type = "submit";
      document
        .getElementById("bulkUploadBtn")
        .classList.remove("btn-secondary");
      document.getElementById("bulkUploadBtn").classList.add("btn-info");
      document.getElementById("processingtextId").setAttribute("hidden", true);
      return true;
    }
  };

  changeFileHandler = (e) => {
    document.getElementById("bulkUploadBtn").type = "button";
    document.getElementById("bulkUploadBtn").classList.remove("btn-info");
    document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
    document.getElementById("processingtextId").removeAttribute("hidden");
    this.setState({ loadFile: e.target.files[0] });
    document.getElementById("inValidDataInfo").innerHTML = "";

    var extentions = [
      "config",
      "exe",
      "js",
      "jsx",
      "svg",
      "JPG",
      "jpg",
      "jpeg",
      "GIF",
      "gif",
      "PNG",
      "png",
      "BMP",
      "bmp",
      "html",
      "xls",
      "doc",
      "docx",
      "ppt",
      "pptx",
      "pdf",
      "pdfx",
      "html",
      "css",
      "sh",
      "php5",
      "pht",
      "phtml",
      "shtml",
      "asa",
      "cer",
      "asax",
      "swf",
      "xap",
      "php",
      "htaccess",
      "xml",
      "xds",
      "asp",
      "aspx",
      "java",
      "c",
      "c++",
      "ctl",
      "conf",
      "qewe",
      "encrypted",
      "enc",
      "crypted",
      "locked",
      "crypto",
      "crypt",
      "txt",
    ];
    var check = !extentions.includes(e.target.files[0].name);
    if (check) {
      // console.log("hit");
      if (
        e.target.files[0].type === "" ||
        e.target.files[0].type === "text/csv" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // console.log("hit");
        // console.log(fileData);
        // console.log(typeof fileData);

        if (
          e.target.files[0].type === "" ||
          e.target.files[0].type === "text/csv"
        ) {
          this.setState({ inputXlFile: false });
          // console.log('hit')
          this.csvFileValidationsForInputFile(e.target.files[0]);
        } else if (
          e.target.files[0].type === "application/vnd.ms-excel" ||
          e.target.files[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const file = e.target.files[0];
          const reader = new FileReader();
          var jsondata = [];
          reader.onload = async (evt) => {
            const data = evt.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false });
            // console.log(jsonData, "json data");
            // jsonData.map((item)=>{
            var result = this.validateAllValues(jsonData);
            // })
            // Convert JSON data to CSV format with custom date formatting
            var csvData = jsonData.reduce((csv, row, index) => {
              if (index === 0) {
                // Add header row
                csv += Object.keys(row).join(",") + "\n";
              }

              // Format the invoicing_date field to mm-dd-yyyy
              // if (row["Invoicing Date"]) {
              //   const dateParts = row["Invoicing Date"].split("/");
              //   if (dateParts.length === 3) {
              //     const month = dateParts[0].padStart(2, "0");
              //     const day = dateParts[1].padStart(2, "0");
              //     const year = dateParts[2];
              //     row["Invoicing Date"] = `${month}/${day}/${year}`;
              //   }
              // }

              // Add row data
              csv +=
                Object.values(row)
                  .map((value) => {
                    // Check if the value is a number and convert it to a number type
                    if (!isNaN(value)) {
                      if (value.includes(".")) {
                        return parseFloat(value);
                      } else {
                        return parseInt(value, 10);
                      }
                    }
                    return `"${value}"`;
                  })
                  .join(",") + "\n";
              return csv;
            }, "");
            // console.log(csvData,'csv')
            await this.setState({
              loadFile: csvData,
              inputXlFile: true,
            });
            if (result) {
              // console.log('hit after vcalidation')
              this.csvFileValidationsForInputFile(csvData);
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle:
            "Please upload file having extensions .csv or .xlsx only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv or .xlsx only.",
      });
    }

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "FO Details ",
      activity: "clicked on Choose file Button",
      event: "Chosen Input file ",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };
  closeAlert = () => {
    this.setState({ show: false, overly: "show-n", loadshow: "show-n" });
  };

  onClickRunPlan = async (e) => {
    e.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    // console.log(this.state.vehicleConfigs,'lll')
    let planData = new FormData();
    if (this.state.inputXlFile) {
      const csvBlob = new Blob([this.state.loadFile], { type: "text/csv" });
      // console.log('hello',this.state.loadFile)
      planData.append("loadFile", csvBlob, "input.csv");
    } else {
      planData.append("loadFile", this.state.loadFile);
    }

    planData.append("useremail", localStorage.getItem("email"));
    planData.append("client", localStorage.getItem("client"));

    redirectURL
      .post("/actualdatafo/validateInputFoNumberForDuplicates", planData)
      .then((response) => {
        var newStatusCode = response.data.status;
        if (newStatusCode == 1) {
          redirectURL
            .post("/actualdatafo/insertactualdatafo", planData)
            .then(async (response) => {
              // console.log(response.data,'datta')
              if (response.data.status === "success") {
                await this.setState({
                  showSlideBlockUpload: "",
                  basicTitle: "Uploaded",
                  basicType: "success",
                  show: true,
                  loadFile: "",
                  loadshow: "show-n",
                  overly: "show-n",
                });
              } else {
                this.setState({
                  showSlideBlockUpload: "",
                  basicTitle: "Failed",
                  basicType: "danger",
                  show: true,
                  loadFile: "",
                  loadshow: "show-n",
                  overly: "show-n",
                });
              }
            })
            .catch((e) => {
              console.log(e);
              this.setState({
                showSlideBlockUpload: "",
                basicTitle: "Failed",
                basicType: "danger",
                show: true,
                loadFile: "",
              });
            });
          this.getActualDataFO();
        } else {
          if (newStatusCode == 2) {
            this.setState({
              processErrMsg: response.data.message,
              processErrShow: 1,
              droppedList: response.data.droppedList,
              loadshow: "show-n",
            });
          }
        }
      });
    $("#uploadFile").val("");
    $("#vehiclesFile").val("");

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Fo Details ",
      activity: "clicked on save Button",
      event: "Upload fo data",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };
  viewPlanDetails = () => {};
  viewCounters = async (row) => {
    var { data } = row;
    var { fodatauniqueid } = data;
    var counterColDefs = [
      {
        headerName: "No of Trips",
        field: "no_trips",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      // {
      //   headerName: "Start Execution Date",
      //   field: "start_execution_date",
      //   width: 160,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      {
        headerName: "Total Quantity",
        field: "total_quantity",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Total Volume",
        field: "total_volume",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Total Weight",
        field: "total_weight",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      // {
      //   headerName: "End Execution Date",
      //   field: "end_execution_date",
      //   width: 160,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
    ];
    await this.setState({
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: counterColDefs,
          overlayNoRowsTemplate: "No rows to show",
          onCellClicked: this.viewPlanDetails,
          height: 100,
        },
        getDetailRowData: async function (param) {
          param.successCallback([]);
          await redirectURL
            .post("/actualdatafo/getComparisionData", {
              fodatauniqueid,
              fo_counter: 1,
            })
            .then(async (response) => {
              var data = [response.data.fo_counter_data];
              param.successCallback(data);
            })
            .catch(function (error) {
              console.log(error);
              var errDetails = {
                url: "/actualdatafo/getComparisionData",
                error,
                screen: "Summary Dashboard",
              };
              redirectURL.post("/master/logErrorsForApiCalls", errDetails);
            });
        },
        masterDetail: true,
      },
    });

    if (row.colDef.field == "run_history") {
      row.node.setExpanded(!row.node.expanded);
    } else {
      row.node.setExpanded(false);
    }
  };
  viewOrderDetails = (params) => {
    var reqparams = {};
    reqparams.consolidatedTrnsxId = params.data.consolidatedTrnsxId;
    redirectURL
      .post("master/getConsolTranxDetails", reqparams)
      .then((response) => {
        this.setState({
          inputDetails: response.data,
          showInputSlider: "slide45",
          overly: "show-m",
        });
      });

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan ",
      activity: "clicked on View Input Button",
      event: `Shown ConsolTranxDetails for ${params.data.consolidatedTrnsxId} `,
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  getplandetails = (propdata) => {
    // var data = propdata.data;
    // console.log("here get details", propdata.data);

    let data_type = this.state.data_type;
    let location = this.state.location.value;

    var data = propdata.data;
    let basestring = data.fodatauniqueid;
    let encryptedstring = window.btoa(basestring);
    // window.location.href = '/viewdispatchplandetails?'+encryptedstring;
    window.open(
      window.location.origin + "/compareactual?" + encryptedstring,
      "_blank"
    );
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Fo Details ",
      activity: "clicked on View Details Button",
      event: `Redirected to view page for ${encryptedstring} id `,
      data_type: data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  boundTypeLocations = async (param) => {
    var plantCodes = [];
    await redirectURL
      .post("master/getBoundTypeLocations", { data_type: 2 })
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              plantCodes.push(item.location_name);
            });
            await this.setState({
              plantCodes,
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  onclickUploadFileButton = () => {
    $("#uploadFile").val("");
    let logParams = {
      // location_code: this.state.location.value,
      // location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Consolidated Run Plan",
      activity: "clicked on Upload File Option in the slide",
      event: "File Upload slide opened",
      // data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onClickDownloadSample = () => {
    let logParams = {
      // "location_code" : this.state.location.value,
      // "location_name" : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Run New Plan",
      activity: "clicked on Download Sample Data Button in the slider",
      event: "Sample template has been Downloaded",
      // "data_type" : this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // January is 0
    const day = String(now.getDate()).padStart(2, "0");
    // const hours = String(now.getHours()).padStart(2, "0");
    // const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  onClickGetData = async () => {
    if (this.state.fromDate > this.state.toDate) {
      this.setState({
        show: true,
        basicTitle: "From Date should be less than To Date",
        basicType: "danger",
      });
      return;
    }
    await this.getActualDataFO();
  };
  onChangeDateHandle = (event) => {
    // console.log("first", event.target.value, event.target.name);
    var name = event.target.name,
      value = event.target.value;
    this.setState({ [name]: value });
  };
  componentDidUpdate() {
    this.removeDatepickerClass();
  }
  removeDatepickerClass() {
    var toDateElement = document.getElementById("toDate");
    var fromDateElement = document.getElementById("fromDate");
    if (toDateElement) {
      // console.log("hit heere");
      toDateElement.classList.remove("hasDatepicker");
      if (window.jQuery && toDateElement.datepicker) {
        window.jQuery(toDateElement).datepicker("destroy");
      }
    }
    if (fromDateElement) {
      fromDateElement.classList.remove("hasDatepicker");
      if (window.jQuery && fromDateElement.datepicker) {
        window.jQuery(fromDateElement).datepicker("destroy");
      }
    }
    const datepickerDiv = document.getElementById("ui-datepicker-div");
    if (datepickerDiv) {
      datepickerDiv.parentNode.removeChild(datepickerDiv);
      // console.log("Datepicker div removed");
    } else {
      // console.log("No datepicker div found");
    }
  }

  fixProcess = () => {
    this.setState({
      processErrMsg: "",
      processErrShow: 0,
      loadFile: "",
    });
    $("#uploadFile").val("");
    //document.getElementById("upform").reset();
  };

  render() {
    var templatePath = require("../../../assets/json/fo_input_data_template.csv");
    var colDefs = [
      {
        headerName: "",
        field: "id",
        width: 100,
        params: {
          buttonName: "View",
          iconName: "fa fa-eye",
          buttonCls: "btn btn-danger",
          onClickFunction: this.getplandetails,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: "View Details",
        field: "run_history",
        width: 100,
        params: {
          buttonName: "View",
          iconName: "fa fa-eye",
          buttonCls: "btn btn-info",
          onClickFunction: this.viewCounters,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: "Upload ID",
        field: "fodatauniqueid",
        width: 130,
      },
      {
        headerName: "Created Date",
        field: "created_date",
        width: 130,
        valueGetter: (params) => {
          if (
            params.data.created_date !== "" &&
            params.data.created_date !== undefined
          ) {
            return getHyphenDDMMMYYYYHHMM(params.data.created_date);
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "Run By",
        field: "useremail",
        width: 200,
      },
      {
        headerName: "Locations",
        field: "location_names",
        width: 200,
      },
      {
        headerName: "Zones",
        field: "zones",
        width: 200,
      },
      // {
      //   headerName: "No of Executions",
      //   headerTooltip: "No of Executions",
      //   // field: "useremail",
      //   width: 150,
      //   valueGetter: (params) => {
      //     // console.log("first", params.data);
      //     return Object.keys(params.data.plant_wise_executions).length;
      //   },
      //   cellStyle: { textAlign: "center" },
      // },
      {
        headerName: "Start Execution Date",
        field: "execution_start_date",
        width: 160,
        valueGetter: (params) => {
          // return getHyphenYYYYMMDDHHMMSS(params.data.execution_start_date);
          return moment
            .parseZone(params.data.execution_start_date)
            .utcOffset("+05:30")
            .format("YYYY-MM-DD HH:mm");
        },
      },
      {
        headerName: "End Execution Date",
        field: "execution_end_date",
        width: 160,
        valueGetter: (params) => {
          //  moment.parseZone(params.data.execution_end_date);
          return moment
            .utc(params.data.execution_end_date)
            .utcOffset("+05:30")
            .format("YYYY-MM-DD HH:mm");
        },
      },
    ];
    var inputDetailscolsDefs = [
      {
        headerName: "Pick Up Location (Source)",
        field: "pickup_location_code",
        width: "150",
        // valueGetter: (params) => {
        //   console.log(params.data, "params");
        // },
      },
      {
        headerName: "Drop Location Code (Destination)",
        field: "drop_location_code",
        width: "150",
      },
      {
        headerName: "Drop Location Name (Destination)",
        field: "drop_location_name",
        width: "200",
      },
      {
        headerName: "Order Number",
        field: "order_number",
        width: "100",
      },
      {
        headerName: "Invoicing Date",
        field: "invoicing_date",
        width: "120",
      },
      {
        headerName: "Quantity",
        field: "quantity",
        width: "100",
      },
      {
        headerName: "Demand Volume (M³)",
        field: "demand_cmt",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Weight (Kgs)",
        field: "weight_tons",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Dealer Available From",
        field: "dealer_available_start",
        width: "110",
      },
      {
        headerName: "Dealer Available To",
        field: "dealer_available_end",
        width: "120",
      },
      {
        headerName: "Delivery Date",
        field: "delivery_date",
        width: "120",
      },
    ];

    var columnwithDefsForDropped = [
      {
        headerName: "Fo Number",
        field: "dealer_code",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      // {
      //   headerName: "Demand",
      //   field: "demand",
      //   width: 130,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      {
        headerName: "Remarks",
        field: "remarks",
        width: 200,
        filter: true,
        resizable: true,
        editable: false,
        cellRenderer: (params) => {
          if (params.value === null || params.value === undefined) {
            return "FO number is already uploaded";
          } else {
            return params.value;
          }
        },
      },
    ];

    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <div className="col-sm-12 " style={{ marginLeft: "10px" }}>
          <h5 className="fbold  d-flex justify-content-between">
            <span>FO Details</span>
          </h5>
        </div>
        <div className="col-md-12 ml-0 mb-0 pb-0">
          <div className="col-md-2">
            <div className="form-group ">
              <label htmlFor="fromDate" className="col-form-label f12">
                From Date
              </label>
              <input
                type="date"
                className="form-control"
                name="fromDate"
                id="fromDate"
                autoComplete="off"
                onKeyDown={(e) => e.preventDefault()}
                max={this.getCurrentDateTime()}
                onChange={this.onChangeDateHandle}
                onClick={this.removeDatepickerClass}
                value={this.state.fromDate}
                // readonly
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group ">
              <label htmlFor="toDate" className="col-form-label f12">
                To Date
              </label>
              <input
                type="date"
                className="form-control"
                name="toDate"
                id="toDate"
                autoComplete="off"
                onKeyDown={(e) => e.preventDefault()}
                max={this.getCurrentDateTime()}
                onChange={this.onChangeDateHandle}
                value={this.state.toDate}
                onClick={this.removeDatepickerClass}
                // readonly
              />
            </div>
          </div>
          <div className="col-md-2">
            <button
              type="button"
              onClick={this.onClickGetData}
              className="btn btn-warning mt-30px"
              style={{ marginTop: "35px" }}
            >
              Get Data
            </button>
          </div>
        </div>
        <div className="col-sm-12 float-right mt-0 pt-0">
          <button
            onClick={this.onClickUploadFile}
            className="btn-warning float-right"
            style={{ fontWeight: "bold", borderRadius: "2px" }}
          >
            Upload FO Data
          </button>
        </div>
        <div className="col-md-12">
          <div class="row mb-10p">
            <div className={"mxheit col-sm-12 "}>
              {/* <h5>Recent Plans</h5> */}
              <div
                id="myGrid1"
                style={{ width: "100%", height: "80vh" }}
                className={"ag-theme-balham"}
              >
                <AgGridReact
                  // modules={this.state.modules}
                  columnDefs={colDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.conPlanData}
                  enableCharts={false}
                  onGridReady={this.onGridReady}
                  onGridState={this.onGridState}
                  frameworkComponents={this.state.frameworkComponents}
                  statusBar={this.state.statusBar}
                  sideBar={this.state.sideBar}
                  stopEditingWhenGridLosesFocus={true}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  gridOptions={{
                    context: { componentParent: this },
                  }}
                  enableRangeSelection={true}
                  enableCellContextMenu={true}
                  // allowContextMenuWithControlKey={true}
                  suppressContextMenu={false}
                  masterDetail={true}
                  detailCellRendererParams={this.state.detailCellRendererParams}
                  rowClassRules={this.state.rowClassRules}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={"loader " + this.state.loadshow}></div>
        <div
          style={{ color: "black" }}
          className={"sliderBlock2 " + this.state.showSlideBlockUpload}
        >
          <h5 className="crd-bg p-10p">Upload FO Data</h5>
          <div className="row">
            {this.state.processErrShow == 0 ? (
              <form onSubmit={this.onClickRunPlan}>
                <div className="col-sm-12">
                  <div className="form-group ml-2">
                    <a
                      className="btn btn-warning"
                      href={templatePath}
                      target="_blank"
                      onClick={this.onClickDownloadSample}
                    >
                      Download Sample Template
                    </a>
                  </div>

                  <div className="form-group col-sm-12 mb-10p">
                    <label style={{ color: "#000" }}>Upload File</label>
                    <input
                      type="file"
                      name="uploadFile"
                      id="uploadFile"
                      onChange={this.changeFileHandler}
                      className="form-control"
                      onClick={this.onclickUploadFileButton}
                      required
                    />
                  </div>
                </div>
                <span
                  id="processingtextId"
                  hidden
                  className=" col-sm-12 red ml-2"
                >
                  File is processing... plz wait...
                </span>
                <div className="form-group col-sm-12 mb-20p ml-3 mt-40p">
                  <div className="mt-10p">
                    <button
                      // disabled
                      type="submit"
                      id="bulkUploadBtn"
                      className="btn btn-info"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={this.hideSlideBlock.bind(this)}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
                <div
                  id="inValidDataInfo"
                  className="col-sm-12 pl-15p"
                  style={{ color: "red" }}
                ></div>
              </form>
            ) : (
              <div className="col-sm-12 p-10p" style={{ margin: "1em" }}>
                <p style={{ marginBottom: "1em" }}>
                  {this.state.processErrMsg}
                </p>
                {this.state.droppedList.length > 0 ? (
                  <div
                    id="myGrid"
                    style={{
                      width: "100%",
                      height: "300px",
                      marginBottom: "1em",
                    }}
                    className={"ag-theme-balham " + this.state.showGridData}
                  >
                    <AgGridReact
                      // modules={this.state.modules}
                      columnDefs={columnwithDefsForDropped}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.droppedList}
                      enableCharts={false}
                      // autoGroupColumnDef={this.state.autoGroupColumnDef}
                      onGridReady={this.onGridReady1}
                      onGridState={this.onGridState1}
                      frameworkComponents={this.state.frameworkComponents}
                      statusBar={this.state.statusBar}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={false}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // components={this.state.components}
                      enableRangeSelection={true}
                      //onCellClicked={this.onCellClicked}
                      // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
                  </div>
                ) : (
                  ""
                )}
                <button
                  type="button"
                  onClick={this.fixProcess}
                  className="btn btn-success"
                >
                  Fix and Reupload
                </button>
              </div>
            )}
          </div>
        </div>

        <div className={"sliderBlock2 " + this.state.showInputSlider}>
          <h5 className="crd-bg p-10p">Input Details</h5>
          <div className="col-sm-12">
            <div
              id="myGrid2"
              style={{ width: "100%", height: "80vh" }}
              className="col-sm-12 ag-theme-balham dropdown"
            >
              <AgGridReact
                // modules={this.state.modules}
                rowData={this.state.inputDetails}
                columnDefs={inputDetailscolsDefs}
                gridOptions={{ context: { componentParent: this } }}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                enableRangeSelection={true}
              />
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.hideSlideBlock.bind(this)}
        ></div>
      </>
    );
  }
}

function isDateFormatValid(date) {
  const dateParts = date.split(/[-/]/);

  if (dateParts.length !== 3) {
    return false;
  }

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  const formattedDate = new Date(year, month - 1, day);

  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

// function availabilityForValidation(time) {
//   var [date, time] = time.split(" ");
//   // console.log(date, "datae",time);
//   // var timeparts=time.split(':')
//   const dateParts = date.split(/[-/]/);
//   if (dateParts.length !== 3) {
//     return false;
//   }

//   const day = parseInt(dateParts[0], 10);
//   const month = parseInt(dateParts[1], 10);

//   const year = parseInt(dateParts[2], 10);
//   if (isNaN(day) || isNaN(month) || isNaN(year)) {
//     return false;
//   }

//   const formattedDate = new Date(year, month - 1, day);

//   if (
//     formattedDate.getDate() !== day ||
//     formattedDate.getMonth() !== month - 1 ||
//     formattedDate.getFullYear() !== year
//   ) {
//     return false;
//   }

//   return true;
// }

function availabilityForValidation(time) {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
  if (!regex.test(time)) {
    return false; // Invalid format
  }

  const [dateStr, timeStr] = time.split(" ");
  const [year, month, day] = dateStr.split("-");
  const [hour, minute] = timeStr.split(":");

  // Convert parts to integers and validate
  const parsedYear = parseInt(year, 10);
  const parsedMonth = parseInt(month, 10);
  const parsedDay = parseInt(day, 10);
  const parsedHour = parseInt(hour, 10);
  const parsedMinute = parseInt(minute, 10);

  if (
    isNaN(parsedYear) ||
    isNaN(parsedMonth) ||
    isNaN(parsedDay) ||
    isNaN(parsedHour) ||
    isNaN(parsedMinute) ||
    parsedMonth < 1 ||
    parsedMonth > 12 ||
    parsedDay < 1 ||
    parsedDay > 31 ||
    parsedHour < 0 ||
    parsedHour > 23 ||
    parsedMinute < 0 ||
    parsedMinute > 59
  ) {
    return false; // Invalid date or time values
  }

  // Validate the date using a Date object
  const formattedDate = new Date(
    parsedYear,
    parsedMonth - 1,
    parsedDay,
    parsedHour,
    parsedMinute
  );
  return (
    formattedDate.getFullYear() === parsedYear &&
    formattedDate.getMonth() === parsedMonth - 1 &&
    formattedDate.getDate() === parsedDay &&
    formattedDate.getHours() === parsedHour &&
    formattedDate.getMinutes() === parsedMinute
  );
}

function isValidWeight(weight) {
  if (weight.includes(",")) {
    return false;
  } else if (parseFloat(weight) > 50000) {
    return false;
  } else {
    return true;
  }
}

function GetSortDescOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

function isDateFormatValidforXLSX(date) {
  // console.log(date, 'date');
  let dateParts = date.split("/");

  if (dateParts.length !== 3) {
    return false;
  }

  var month = parseInt(dateParts[0], 10);
  var day = parseInt(dateParts[1], 10);
  var year = parseInt(dateParts[2], 10);

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return false;
  }

  // Adjust the year if it's two digits
  if (year >= 0 && year < 100) {
    // Assuming if the year is less than 50, it belongs to the 21st century, otherwise 20th century
    year += year < 50 ? 2000 : 1900;
  }

  var formattedDate = new Date(year, month - 1, day);
  // console.log(formattedDate,'formated date')
  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

function isDropCodeValid(code, plantCodes) {
  if (plantCodes.includes(code)) {
    return false;
  } else {
    return true;
  }
}

function isPlantValid(code, plantCodes) {
  if (plantCodes.includes(code)) {
    return true;
  } else {
    return false;
  }
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
